export default {
    emailRegex: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    mobileRegex: /^[0-9۰-۹]{9,15}$/,
    organizationPhone: /^[0-9۰-۹ -]{3,15}$/,
    mobileWithCountryCodeRegex: /^[0-9۰-۹]{12,15}$/,

    /* https://stackoverflow.com/a/3809435/5694847 */
    urlRegex:
        /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
    linkedinLinkRegex: /^https:\/\/([a-z]{2,3}\.)?linkedin\.com\/.*$/,
    number: /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
};
