import mut from './types';
const state = () => ({
    userInfo: {},
});

const getters = {
    userInfo: (state) => state.userInfo,
    userId: (state) => state.userInfo?.id,
    role: (state) => state.userInfo?.role,
};

const mutations = {
    [mut.SET_USER_INFO](state, payload) {
        state.userInfo = { ...state.userInfo.user, ...payload };
    },
};

const actions = {
    // userInfo
    setUserInfo({ commit }, info: any): void {
        commit(mut.SET_USER_INFO, info);
    },
};

export default {
    namespaced: true,

    state,
    mutations,
    actions,
    getters,
};
