import { SearchService } from '~/Services/SearchService';

const state = {
    mostVisited: [],
};

const mutations = {
    setMostVisited(state: any, payload: any) {
        state.mostVisited = payload;
    },
};

const getters = {
    getMostVisited(state) {
        return state.mostVisited;
    },
};

const actions = {
    async nuxtServerInit(vuexContext: any) {
        const searchService = SearchService.getInstance();
        const res = await searchService.getMostVisited();

        await vuexContext.dispatch('setMostVisited', res.docs);
    },

    setMostVisited(vueContext: any, payload: any) {
        vueContext.commit('setMostVisited', payload);
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
