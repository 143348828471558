import { Plugin } from '@nuxt/types';

const accessor: Plugin = ({ route }) => {
    route.meta.navbar = { desktop: 'desktop', mobile: 'mobile' };
    // return route.meta.navbar.map((meta:any) => meta.navbar ? 'desktop' : 'mobile')
    // Take the last value (latest route child)
    // const theme = route.meta.reduce((theme, meta) => meta.theme || theme, 'light')
    // store.commit('SET_THEME', theme)
};

export default accessor;
