import { ApiConfig } from '~/config/api.config';
import { URI } from '~/utils/uri';

export default {
    /**
     * Sends a request and creates transaction history file to be downloaded by client
     *
     * @param  {string} token Token
     * @param  {any} query Query strings
     *
     * @returns {Promise<void>}
     */
    async fetchTransactionExcel(token: string, query: any): Promise<void> {
        try {
            const transactions = await this.sendTransactionRequest(token, query);
            const link = this.createDownloadLink(transactions);

            link.click();
            link.remove();
        } catch (error) {
            throw error.response;
        }
    },

    /**
     * Send transaction history request
     *
     * @param  {} query Query strings
     *
     */
    async sendTransactionRequest(token, query) {
        return await this.ctx.$axios.get(
            URI({
                base: ApiConfig.user.uri,
                endpoint: ApiConfig.user.transactionWithdraw,
                query: query,
            }),
            {
                headers: {
                    'x-token': token,
                },
            }
        );
    },

    /**
     * Creates download link for the transaction file
     *
     * @param  {} response Response
     *
     * @returns {HTMLAnchorElement} Html a tag
     */
    createDownloadLink(response): HTMLAnchorElement {
        const blob = new Blob(['\ufeff', response.data], { type: response.headers.type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download =
            this.fetchFileName(response.headers['content-disposition']) ?? this.defaultFileName();

        return link;
    },

    /**
     * Returns transaction file name from what server header has sent
     *
     * @param  {string} headerFileName Header value
     *
     * @returns {string} File name
     */
    fetchFileName(headerFileName: string): string {
        return headerFileName?.split('filename=')?.[1];
    },

    /**
     * Returns a default name for transactions excel file
     *
     * @returns {string} File name
     */
    defaultFileName(): string {
        return `Transactions-${new Date().getTime()}.csv`;
    },

    /**
     * Uploads transaction file in order to update transactions
     *
     * @param  {} query Query strings
     *
     */
    async uploadTransactions(token, file) {
        try {
            return await this.ctx.$axios.patch(
                URI({
                    base: ApiConfig.user.uri,
                    endpoint: ApiConfig.user.transactionUpload,
                }),
                this.createFormData(file),
                {
                    headers: {
                        'x-token': token,
                    },
                }
            );
        } catch (error) {
            throw error.response;
        }
    },
    createFormData(file): FormData {
        const formData = new FormData();
        formData.append('withdrawal', file);

        return formData;
    },

    async fetchOtp() {
        return await this.ctx.$axios.post(
            URI({
                base: ApiConfig.user.uri,
                endpoint: ApiConfig.user.accountingOtp,
            })
        );
    },

    async verifyOtp(code: number) {
        return await this.ctx.$axios.post(
            URI({
                base: ApiConfig.user.uri,
                endpoint: ApiConfig.user.accountingOtpVerify,
            }),
            {
                code: code,
            }
        );
    },
};
