//
//
//
//
//
//
//
//

export default {
    props: {
        size: {
            type: Number,
            default: 46,
        },
        color: {
            type: String,
            default: '#0bafa8',
        },
        src: {
            type: String,
        },
    },
    data() {
        return {
            imageSrc: undefined,
            defaultImg: '../../../static/images/avatarMale.svg',
        };
    },
    computed: {
        imageSource() {
            return this.$store.state?.auth?.user?.avatars &&
                this.$store.state?.auth?.user?.avatars.length !== 0
                ? this.getPath(this.$store.state.auth.user.avatars[0])
                : require('../../static/images/avatarMale.svg');
        },
    },
    methods: {
        getPath(t) {
            return process.env.VUE_APP_API_STORAGE_URL_ONLY + t?.bucket + '/' + t?.filenameDisk;
        },
    },
};
