import { NuxtAxiosInstance } from '@nuxtjs/axios';
import store from '~/store';

// import router from "../../router/index"
// import {AuthenticationService} from "@/Services/AuthenticationService"
// import {LanguageService} from "../LanguageServices"

// let axios: NuxtAxiosInstance
let $axios: NuxtAxiosInstance;

export function initializeAxios(axiosInstance: NuxtAxiosInstance) {
    $axios = axiosInstance;
    $axios.setBaseURL(process.env.VUE_APP_API_URL);
    // $axios.onRequest(config => {
    //   config.withCredentials = true;

    //   return config;
    // });
}

export { $axios };

export const get = async (url: string, params: any, headers: any) => {
    try {
        const response = await $axios.$get(url, { headers, params });
        return response;
    } catch (error) {
        // if(error.meta.statusCode === '401'){
        //   this.$router.push(this.localePath('/'))
        // }
        throw error;
    }
};

export const post = async (url: string, body: any, headers: any, query: any, config: any = {}) => {
    try {
        const response = await $axios.post(url, body, { headers, params: query, ...config });
        return response;
    } catch (error) {
        throw error;
    }
};

export const put = async (url: string, body: any, headers: any, query: any, config: any = {}) => {
    try {
        const response = await $axios.put(url, body, { headers, params: query, ...config });
        return response;
    } catch (error) {
        throw error;
    }
};

export const del = async (url: string, headers: any) => {
    try {
        const response = await $axios.delete(url, { headers });
        return response;
    } catch (error) {
        if (error && error.response) {
            const axiosError = error as any;
            return axiosError.response.data;
        }
    }
};
