import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=91a01c92&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=91a01c92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91a01c92",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IModaller: require('/app/components/i/modaller/index.vue').default,ILogo: require('/app/components/i/logo/index.vue').default,ICreateContentMenu: require('/app/components/i/create-content-menu/index.vue').default,HeaderSearch: require('/app/components/header/Search.vue').default,ProfileAvatarMenu: require('/app/components/profile/avatar-menu/index.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBadge,VBtn,VContainer,VIcon,VImg})
