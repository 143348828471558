//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ChatService } from '~/Services/ChatService';
import { EventBus } from '@/utils/event-bus';

export default {
    props: {
        transparentOnTop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modaller: {
                width: '500',
                persistent: false,
            },
            transparent: this.transparentOnTop,
        };
    },
    computed: {
        ...mapGetters('search', { searchBar: 'searchBar' }),
        userInfo() {
            return this.$store.state.auth.user;
        },
        isBussiness() {
            return this.userInfo?.role === 'business';
        },
        unreadMessages() {
            return this.$store.getters['chatStore/getUnreadMessagesCount'];
        },
        active() {
            if (this.$route.path.match(/^\/(en)?\/?$/)) {
                return 'main';
            }
            if (this.$route.path.match(/UW5ljnOLa\/G2Vm/)) {
                return 'contact';
            }
            if (this.$route.path.match(/UW5ljnOLa/)) {
                return 'about';
            }
            return null;
        },
    },
    created() {
        this.$i18n.onLanguageSwitched = (newLocale) => {
            newLocale !== 'fa' ?? '' ? (this.$vuetify.rtl = true) : (this.$vuetify.rtl = false);
        };

        if (this.$auth.loggedIn) {
            this.newMessages();
        }
        EventBus.$on('open-search', () => {
            this.setSearchBar(true);
        });
    },
    // NOTE uncomment this part if you want to do some actions on scrilling
    mounted() {
        if (this.transparentOnTop) {
            this.onScroll();
            window.addEventListener('scroll', this.onScroll);
        }
    },
    beforeDestroy() {
        if (this.transparentOnTop) {
            window.removeEventListener('scroll', this.onScroll);
        }
    },
    methods: {
        ...mapActions('search', { setSearchBar: 'setSearchBar' }),
        cancelSearch() {
            this.setSearchBar(false);
        },
        openSearch() {
            this.setSearchBar(true);
        },
        async newMessages() {
            const chatService = new ChatService();
            const response = await chatService.getUserNewMessages();
            if (response.status === 200 && response.data) {
                const newMessages = response?.data?.reduce((total, item) => {
                    total += item.newMessageCount;
                    return total;
                }, 0);

                this.$store.dispatch('chatStore/setUnreadMessages', {
                    type: 'TOTAL',
                    totalNewMessages: newMessages,
                    data: response.data,
                });
            }
        },
        openChat() {
            if (this.userInfo.phoneVerified || this.userInfo.emailVerified) {
                this.$router.push(this.localePath('/chat'));
            } else if (!this.userInfo.phoneVerified && !this.userInfo.emailVerified) {
                this.$store.dispatch('setSnackBar', {
                    toggle: true,
                    message: this.$t('info.messages.verifyAccount'),
                    color: 'red',
                });
            }
        },
        openLoginRegisterModal(tab) {
            this.$refs.modaller.openModal(
                'ModalLoginRegister',
                {
                    tab,
                },
                {}
            );
        },
        onScroll() {
            const scrollTopOffset = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTopOffset < 30) {
                this.transparent = true;
            } else {
                this.transparent = false;
            }
        },
    },
};
