const servicePath = '/api/v1/ip';

export default {
    async get(token: string) {
        try {
            let apiResponse;

            // logged in user
            if (this.ctx.$auth?.$state?.loggedIn) {
                const requestPrivateVersion = token.startsWith('private-');
                const route = `${servicePath}/process/${encodeURIComponent(
                    requestPrivateVersion ? token.split('private-')[1] : token
                )}/auth/tabs`;
                apiResponse = await this.ctx.$axios.$get(route, {
                    params: {
                        private: requestPrivateVersion ? 1 : null,
                    },
                });
            }

            // guest user
            else {
                const route = `${servicePath}/process/${token}/tabs`;
                apiResponse = await this.ctx.$axios.$get(route);
            }

            return apiResponse?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async getTabWithToken(processToken: string, tabToken: string): Promise<any> {
        try {
            const requestPrivateVersion = processToken.startsWith('private-');
            const response = await this.ctx.$axios.$get(
                `${servicePath}/process/${
                    requestPrivateVersion ? processToken.split('private-')[1] : processToken
                }/tabs/${tabToken}`,
                {
                    params: { private: requestPrivateVersion ? 1 : null },
                }
            );
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async addTask(pId, tabId, task): Promise<any> {
        try {
            const response = await this.ctx.$axios.$post(
                `${servicePath}/management/infopacks/${pId}/tabs/${tabId}/task`,
                {
                    order: 0,
                    type: 1,
                    access: false,
                    description: '',
                    statusRef: 1,
                    expiresAt: null,
                    location: 'skype',
                    externalLink: 'https://infodeniz.com',
                    ...task,
                }
            );
            return response.data;
        } catch (error) {
            throw error.response || error.notofication || error;
        }
    },

    async updateTask(pId, tabId, task): Promise<any> {
        const { id, ...rest } = task;
        try {
            const response = await this.ctx.$axios.$put(
                `${servicePath}/management/infopacks/${pId}/tabs/${tabId}/task/${id}`,
                rest
            );
            // commit('setTabTasks', response.data);
            return response.data;
        } catch (error) {
            this.ctx.$apiErrorHandler(error);
        }
    },

    async addTaskAsset(pId, tabId, taskId, assetArray): Promise<any> {
        try {
            const response = await this.ctx.$axios.$post(
                `${servicePath}/management/infopacks/${pId}/tabs/${tabId}/task/${taskId}/asset`,
                { id: assetArray }
            );
            // commit('setTabTasks', response.data);
            return response.data;
        } catch (error) {
            this.ctx.$apiErrorHandler(error);
        }
    },

    async deleteTask(pId, tabId, task): Promise<any> {
        const { id } = task;
        try {
            await this.ctx.$axios.$delete(
                `${servicePath}/management/infopacks/${pId}/tabs/${tabId}/task/${id}`
            );
        } catch (error) {
            this.ctx.$apiErrorHandler(error);
        }
    },

    async fetchProcesses({
        offset = 0,
        limit = 10,
    }: {
        offset: number;
        limit: number;
    }): Promise<any> {
        try {
            const response = await this.ctx.$axios.$get(`${servicePath}/process`, {
                params: { offset, limit },
            });
            return response.data;
        } catch (error) {
            this.ctx.$apiErrorHandler(error);
        }
    },

    async fetchMyProcesses({
        offset = 0,
        limit = 10,
        status = [],
        pin = false,
    }: {
        offset: number;
        limit: number;
        status?: Array<number>;
        pin?: boolean;
    }): Promise<any> {
        try {
            const response = await this.ctx.$axios.$get(`${servicePath}/process/myProcess`, {
                params: { offset, limit, status, pin },
            });
            return response;
        } catch (error) {
            this.ctx.$apiErrorHandler(error);
        }
    },

    async doneTask(process: {
        processId: number;
        tabId: number;
        taskId: number;
        status: boolean;
    }): Promise<any> {
        try {
            await this.ctx.$axios.$post(
                `${servicePath}/process/${encodeURIComponent(process.processId)}/tabs/${
                    process.tabId
                }/${process.taskId}/done`,
                { done: process.status }
            );
        } catch (error) {
            this.ctx.$apiErrorHandler(error);
        }
    },

    async doneTab(process: { processId: number; tabId: number; status: boolean }): Promise<any> {
        try {
            await this.ctx.$axios.$post(
                `${servicePath}/process/${encodeURIComponent(process.processId)}/tabs/${
                    process.tabId
                }/done`,
                { done: process.status }
            );
        } catch (error) {
            this.ctx.$apiErrorHandler(error);
        }
    },

    async sendRegisterationRequest(processId: number): Promise<any> {
        try {
            await this.ctx.$axios.$post(`${servicePath}/process/${processId}/register`);
        } catch (error) {
            this.ctx.$apiErrorHandler(error);
        }
    },

    /**
     * @description fetch processes created by user
     * @param userId id of target user
     * @returns any
     */
    async getUserProfileProcesses(userId: number): Promise<any> {
        try {
            const route = `${servicePath}/process/${userId}`;
            const response = await this.ctx.$axios.get(route);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },
};
