export default function (app) {
    if (!process.server) {
        const { store } = app;
        store.commit('changeSkletonLoading', {
            skeltonLoading: true,
        });
        if (performance.navigation.type == 1) {
            store.commit('changeSkletonLoading', {
                skeltonLoading: true,
            });
        }
    }
}
