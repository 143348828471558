import mut from './types';
const state = () => ({
    snackBar: {},
    largeSnackBar: {},
});

const getters = {
    snackBar: (state) => state.snackBar,
    largeSnackBar: (state) => state.largeSnackBar,
};

const mutations = {
    [mut.SET_LARGE_SNACK_BAR](state, payload) {
        state.largeSnackBar = payload;
    },
    [mut.SET_SNACK_BAR](state, payload) {
        state.snackBar = payload;
    },
};

const actions = {
    setSnackBar({ commit }, payload: any): void {
        commit(mut.SET_SNACK_BAR, payload);
    },
    setLargeSnackBar({ commit }, payload: any): void {
        commit(mut.SET_LARGE_SNACK_BAR, payload);
    },
};

export default {
    namespaced: true,

    state,
    mutations,
    actions,
    getters,
};
