

















import { Vue, Component, Action } from 'nuxt-property-decorator';
import copyToClipboard from '~/mixinsjs/copyToClipboard';

@Component({
    name: 'ProfileAvatar',
    mixins: [copyToClipboard],
})
export default class ProfileAvatar extends Vue {
    get userInfo() {
        return this.$store.state.auth.user;
    }

    @Action('setSnackBar') setSnackBar;

    async copyToClipboardFunc() {
        try {
            await this.mxCopyToClipboard(this.userInfo.username);
            this.setSnackBar({
                toggle: true,
                message: this.$t('info.messages.titleCopied').replace(
                    'TITLE',
                    this.$t('info.public.username')
                ),
                color: 'green',
            });
        } catch (error) {
            this.setSnackBar({
                toggle: true,
                message: this.$t('info.messages.copyingFailed'),
                color: 'red',
            });
        }
    }
}
