import mut from './types';

const state = () => ({
    pack: undefined,
    content: undefined,
});

const getters = {
    pack: (state) => state.pack,
    content: (state) => state.content,
    hasAccess: (state) => state.pack?.hasAccess,
};

const mutations = {
    [mut.SET_PACK](state, payload) {
        if (payload) {
            state.pack = {
                ...(state.pack || {}),
                ...payload,
            };
        } else {
            state.pack = undefined;
        }
    },
    [mut.SET_CONTENT](state, payload) {
        if (payload) {
            state.content = {
                ...(state.content || {}),
                ...payload,
            };
        } else {
            state.content = undefined;
        }
    },
};

const actions = {
    setPack({ commit }, data): void {
        commit(mut.SET_PACK, data);
    },
    setContent({ commit }, data): void {
        commit(mut.SET_CONTENT, data);
    },
};

export default {
    namespaced: true,

    state,
    mutations,
    actions,
    getters,
};
