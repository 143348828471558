var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer w-100 p-relative",class:{ 'rounded-lg': _vm.$breakpoint.mdAndUp, 'bg-transparent': _vm.noBg }},[(!_vm.hideTopBtn)?_c('v-btn',{staticClass:"p-absolute bg-white up-btn",attrs:{"icon":"","min-width":"48","min-height":"48","elevation":"2"},on:{"click":_vm.scrollToTop}},[_c('v-icon',{staticClass:"iconiz-chevron-up"})],1):_vm._e(),_vm._v(" "),_c('v-container',[_c('div',{staticClass:"d-flex flex-column g-8 w-100"},[_c('div',{staticClass:"d-flex justify-center"},[_c('i-logo',{staticClass:"ps-4",attrs:{"width":"110"}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-center links"},[_c('v-btn',{class:{
                        'info-black deactive-pointer': _vm.active === 'main',
                        'gray-8': _vm.active !== 'main',
                    },attrs:{"large":"","text":"","nuxt":"","link":"","to":_vm.localePath('/')}},[_vm._v("\n                    "+_vm._s(_vm.$t('info.public.mainPage'))+"\n                ")]),_vm._v(" "),_c('v-btn',{class:{
                        'info-black deactive-pointer': _vm.active === 'contact',
                        'gray-8': _vm.active !== 'contact',
                    },attrs:{"large":"","text":"","nuxt":"","link":"","to":_vm.localePath('/infopacks/UW5ljnOLa/G2Vm')}},[_vm._v("\n                    "+_vm._s(_vm.$t('info.public.contactUs'))+"\n                ")]),_vm._v(" "),_c('v-btn',{class:{
                        'info-black deactive-pointer': _vm.active === 'about',
                        'gray-8': _vm.active !== 'about',
                    },attrs:{"large":"","text":"","nuxt":"","link":"","to":_vm.localePath('/infopacks/UW5ljnOLa')}},[_vm._v("\n                    "+_vm._s(_vm.$t('info.public.aboutUs'))+"\n                ")])],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"footer__e-namad"},[_c('a',{attrs:{"referrerpolicy":"origin","target":"_blank","href":"https://trustseal.enamad.ir/?id=252029&Code=Kf4kBQuXJBl0vSLBDwjG"}},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"id":"Kf4kBQuXJBl0vSLBDwjG","referrerpolicy":"origin","src":"/images/enamad.png","alt":""}})])])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-center g-2"},[_c('v-btn',{attrs:{"icon":"","link":"","href":"https://www.instagram.com/infodeniz","target":"_blank"}},[_c('v-icon',{staticClass:"iconiz-instagram"})],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","link":"","href":"https://www.linkedin.com/company/infodeniz","target":"_blank"}},[_c('v-icon',{staticClass:"iconiz-linkedin"})],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","link":"","href":"https://twitter.com/InfoDeniz","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-twitter")])],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('p',{staticClass:"ps-4 font-14 gray-14"},[_vm._v(_vm._s(_vm.$t('info.messages.allRightsReserved')))])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }