const infoPackDetailStoreModule = {
    state: () => ({
        infoPackCreationData: null,
        infoPackCreationTab: null,
        infopackContentType: {},
        infoPackList: [],
        infoPackContentLoaded: true,
        managementDescriptionSavingStatus: 0,
    }),
    getters: {
        isProcess(state: { infoPackCreationData: { type: number } }) {
            return state.infoPackCreationData?.type == 2;
        },
        isPublished(state: { infoPackCreationData: { publicPermission: any } }) {
            return state.infoPackCreationData && !!state.infoPackCreationData.publicPermission;
        },
        tabTasks(state: { infoPackCreationTab: { infopackTasks: any } }) {
            return state.infoPackCreationTab?.infopackTasks;
        },
        taskById: (state: { infoPackCreationTab: { infopackTasks: any[] } }) => (id: any) => {
            return state.infoPackCreationTab?.infopackTasks?.find(
                (thing: { id: any }) => thing.id === id
            );
        },
        infoPackCreationTab(state: any) {
            return state.infoPackCreationTab;
        },
        infoPackTextContent(state: any) {
            const contentArray =
                state.infoPackCreationTab && state.infoPackCreationTab.infopackContents
                    ? state.infoPackCreationTab.infopackContents.filter(
                          (item: any) => item?.type === 'TEXT'
                      )
                    : [];

            let textContent = '';
            if (contentArray.length) {
                contentArray.map((item: { content: string }) => {
                    textContent += item.content;
                });
            }

            return textContent;
        },
        infoPackLatestContent(state: any) {
            let latest = null;
            if (state.infoPackCreationData && state.infoPackCreationData.infopackContents) {
                const sortedTextContents = state.infoPackCreationData.infopackContents
                    .filter((item: any) => item?.type === 'TEXT')
                    .sort((a: any, b: any) => a.id - b.id);
                if (sortedTextContents.length)
                    latest = sortedTextContents[sortedTextContents.length - 1];
            }
            return latest;
        },
        infoPackTitle(state: any) {
            return (state.infoPackCreationData && state.infoPackCreationData.title) || '';
        },
        infoPackImage(state: any) {
            return state.infoPackCreationTab && state.infoPackCreationTab.infopackContents
                ? state.infoPackCreationTab.infopackContents
                      .filter((item: any) => item?.type === 'IMAGE')
                      .sort((a: { order: number }, b: { order: number }) =>
                          a.order > b.order ? 1 : -1
                      )
                : [];
        },
        infoPackLink(state: any) {
            return state.infoPackCreationTab && state.infoPackCreationTab.infopackContents
                ? state.infoPackCreationTab.infopackContents.filter(
                      (item: any) => item?.type === 'LINK'
                  )
                : [];
        },
        infoPackVideo(state: any) {
            return state.infoPackCreationTab && state.infoPackCreationTab.infopackContents
                ? state.infoPackCreationTab.infopackContents.filter(
                      (item: any) => item?.type === 'VIDEO'
                  )
                : [];
        },
        infoPackAudio(state: any) {
            return state.infoPackCreationTab && state.infoPackCreationTab.infopackContents
                ? state.infoPackCreationTab.infopackContents.filter(
                      (item: any) => item?.type === 'AUDIO'
                  )
                : [];
        },
        infoPackFile(state: any) {
            return state.infoPackCreationTab && state.infoPackCreationTab.infopackContents
                ? state.infoPackCreationTab.infopackContents.filter(
                      (item: any) => item?.type === 'FILE'
                  )
                : [];
        },
        infoPackList(state: { infoPackList: any }) {
            return state.infoPackList;
        },
        getInfoPackContentLoaded(state: { infoPackContentLoaded: any }) {
            return state.infoPackContentLoaded;
        },
        getManagementDescriptionSavingStatus(state: { managementDescriptionSavingStatus: any }) {
            return state.managementDescriptionSavingStatus;
        },
        getInfoPackData(state: { infoPackCreationData: any }) {
            return state.infoPackCreationData;
        },
    },
    mutations: {
        setInfoPackCreationData(state: any, infoPackData: any) {
            state.infoPackCreationData = { ...state.infoPackCreationData, ...infoPackData };
        },
        setInfoPackTabData(state: any, infoPackTabData: any) {
            state.infoPackCreationTab = infoPackTabData;
        },
        setInfopackTabContent(state: any, payload: any) {
            state.infoPackCreationTab = { ...state.infoPackCreationTab, ...payload };
        },
        setTabTasks(state: { infoPackCreationTab: any }, tasks: any) {
            state.infoPackCreationTab = { ...state.infoPackCreationTab, infopackTasks: tasks };
        },
        setInfoTextContent(state: any, content: any) {
            if (!state.infoPackCreationTab) {
                return;
            }
            if (
                !state.infoPackCreationTab.infopackContents.length ||
                !state.infoPackCreationTab.infopackContents.filter(({ type }) => type === 'TEXT')
                    .length
            ) {
                state.infoPackCreationTab.infopackContents.push({ type: 'TEXT', content: '' });
            }

            state.infoPackCreationTab = {
                ...state.infoPackCreationTab,
                infopackContents: state.infoPackCreationTab.infopackContents.map(
                    (x: { type: string }) => (x.type === 'TEXT' ? { ...x, content } : x)
                ),
            };
        },
        setImageData(state: any, infopackImageData: any) {
            const otherContent = state.infoPackCreationTab.infopackContents.filter(
                (item: any) => item.type !== 'IMAGE'
            );

            state.infoPackCreationTab = {
                ...state.infoPackCreationTab,
                infopackContents: [...otherContent, ...infopackImageData],
            };
        },
        setVideoData(state: any, infopackVideoData: any) {
            const otherContent = state.infoPackCreationTab.infopackContents.filter(
                (item: any) => item.type !== 'VIDEO'
            );
            state.infoPackCreationTab = {
                ...state.infoPackCreationTab,
                infopackContents: [...otherContent, ...infopackVideoData],
            };
        },
        setAudioData(state: any, infopackAudioData: any) {
            const otherContent = state.infoPackCreationTab.infopackContents.filter(
                (item: any) => item.type !== 'AUDIO'
            );
            state.infoPackCreationTab = {
                ...state.infoPackCreationTab,
                infopackContents: [...otherContent, ...infopackAudioData],
            };
        },
        setFileData(state: any, infopackFileData: any) {
            const otherContent = state.infoPackCreationTab.infopackContents.filter(
                (item: any) => item.type !== 'FILE'
            );

            state.infoPackCreationTab = {
                ...state.infoPackCreationTab,
                infopackContents: [...otherContent, ...infopackFileData],
            };
        },
        setLinkData(state: any, infopackLinkData: any) {
            const otherContent = state.infoPackCreationTab.infopackContents.filter(
                (item: any) => item.type !== 'LINK'
            );

            state.infoPackCreationTab = {
                ...state.infoPackCreationTab,
                infopackContents: [...otherContent, ...infopackLinkData],
            };
        },
        setFaqData(state: any, infopackFaqData: any) {
            let faqList: any;
            faqList = state.infoPackCreationTab.infopackContents.filter(
                (item: any) => item?.type === 'FAQ'
            );
            faqList = infopackFaqData;
        },
        setDescriptionContent(state: any, content: any) {
            if (!state.infoPackCreationData) {
                state.infoPackCreationData = {};
            }
            if (!state.infoPackCreationData.content) {
                state.infoPackCreationData.content = {};
            }
            state.infoPackCreationData.content = {
                ...state.infoPackCreationData.content,
                ...content,
            };
            state.infoPackCreationData = { ...state.infoPackCreationData };
        },
        setManagementDescriptionSavingStatus(
            state: { managementDescriptionSavingStatus: any },
            payload: any
        ) {
            state.managementDescriptionSavingStatus = payload;
        },

        setInfoPackList(state: { infoPackList: any }, payload: any[]) {
            if (payload?.length) {
                payload = payload.map((infopack: { children: any }) => {
                    return {
                        ...infopack,
                        children: infopack.children || [],
                    };
                });
            }
            state.infoPackList = payload;
        },

        setInfoPackContentLoaded(state: { infoPackContentLoaded: any }, payload: any) {
            state.infoPackContentLoaded = payload;
        },

        setResetStates(state: {
            infoPackCreationData: any;
            infoPackCreationTab: any;
            infopackContentType: {};
            infoPackList: any[];
            infoPackContentLoaded: boolean;
            managementDescriptionSavingStatus: number;
        }) {
            state.infoPackCreationData = null;
            state.infoPackCreationTab = null;
            state.infopackContentType = {};
            state.infoPackList = [];
            state.infoPackContentLoaded = true;
            state.managementDescriptionSavingStatus = 0;
        },

        setTitle(state: any, title: any) {
            if (!state.infoPackCreationData) {
                state.infoPackCreationData = {};
            }
            state.infoPackCreationData.title = title;
            state.infoPackCreationData = { ...state.infoPackCreationData };
        },
    },
    actions: {
        setDescriptionContent(context: any, payload: any) {
            context.commit('setDescriptionContent', payload);
        },
        setTitle(context: any, payload: any) {
            context.commit('setTitle', payload);
        },
        setInfoPackCreationData(context: any, payload: any) {
            context.commit('setInfoPackCreationData', payload);
        },
        setTextContent(context: any, payload: any) {
            context.commit('setInfoTextContent', payload);
        },
        setImageData(context: any, payload: { index: any; payload: any }) {
            context.commit('setImageData', payload);
        },
        setVideoData(context: any, payload: any) {
            context.commit('setVideoData', payload);
        },
        setFileData(context: any, payload: any) {
            context.commit('setFileData', payload);
        },
        setLinkData(context: any, payload: any) {
            context.commit('setLinkData', payload);
        },
        setFaqData(context: any, payload: any) {
            context.commit('setFaqData', payload);
        },
        changeInfoPackList({ commit }: any, payload: any) {
            commit('setInfoPackList', payload);
        },
        setManagementDescriptionSavingStatus(context: any, payload: number) {
            context.commit('setManagementDescriptionSavingStatus', payload);
        },
        setInfopackTabContent({ commit }, payload: any) {
            commit('setInfopackTabContent', payload);
        },
    },
};

export { infoPackDetailStoreModule };
