//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    computed: {
        active() {
            if (this.$route.path.match(/^\/(en)?\/?$/)) {
                return 'main';
            }
            if (this.$route.path.match(/UW5ljnOLa\/G2Vm/)) {
                return 'contact';
            }
            if (this.$route.path.match(/UW5ljnOLa/)) {
                return 'about';
            }
            return null;
        },
    },
};
