import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _83dfe29a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _f7b6fea0 = () => interopDefault(import('../pages/account/infopacks.vue' /* webpackChunkName: "pages/account/infopacks" */))
const _649da80c = () => interopDefault(import('../pages/account/notifications.vue' /* webpackChunkName: "pages/account/notifications" */))
const _24244201 = () => interopDefault(import('../pages/account/processes.vue' /* webpackChunkName: "pages/account/processes" */))
const _17fc993e = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _11c81305 = () => interopDefault(import('../pages/account/wallet.vue' /* webpackChunkName: "pages/account/wallet" */))
const _19e0227a = () => interopDefault(import('../pages/account/management/withdraw.vue' /* webpackChunkName: "pages/account/management/withdraw" */))
const _2044fa56 = () => interopDefault(import('../pages/category-list/index.vue' /* webpackChunkName: "pages/category-list/index" */))
const _02270576 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e6e8cede = () => interopDefault(import('../pages/error/index.vue' /* webpackChunkName: "pages/error/index" */))
const _22604260 = () => interopDefault(import('../pages/forgot/index.vue' /* webpackChunkName: "pages/forgot/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _c613de88 = () => interopDefault(import('../pages/notfound/index.vue' /* webpackChunkName: "pages/notfound/index" */))
const _0d29c7e0 = () => interopDefault(import('../pages/oops/index.vue' /* webpackChunkName: "pages/oops/index" */))
const _cc080460 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _6cfe81d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _1618ddf0 = () => interopDefault(import('../pages/forgot/resetPassword.vue' /* webpackChunkName: "pages/forgot/resetPassword" */))
const _5ddf18f8 = () => interopDefault(import('../pages/forgot/verifyCode.vue' /* webpackChunkName: "pages/forgot/verifyCode" */))
const _1490b7d0 = () => interopDefault(import('../pages/payment/failed.vue' /* webpackChunkName: "pages/payment/failed" */))
const _1f9f5100 = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _1f92080b = () => interopDefault(import('../pages/register/userInfo.vue' /* webpackChunkName: "pages/register/userInfo" */))
const _97234c10 = () => interopDefault(import('../pages/register/verifyCode.vue' /* webpackChunkName: "pages/register/verifyCode" */))
const _4d618ab8 = () => interopDefault(import('../pages/user/reset/index.vue' /* webpackChunkName: "pages/user/reset/index" */))
const _18c2ad48 = () => interopDefault(import('../pages/verify/failed/index.vue' /* webpackChunkName: "pages/verify/failed/index" */))
const _a8351ccc = () => interopDefault(import('../pages/verify/success/index.vue' /* webpackChunkName: "pages/verify/success/index" */))
const _6f5c647f = () => interopDefault(import('../pages/profile/_.vue' /* webpackChunkName: "pages/profile/_" */))
const _54b44abc = () => interopDefault(import('../pages/infopacks/_.vue' /* webpackChunkName: "pages/infopacks/_" */))
const _3834e206 = () => interopDefault(import('../pages/management/_.vue' /* webpackChunkName: "pages/management/_" */))
const _05a46676 = () => interopDefault(import('../pages/process/_.vue' /* webpackChunkName: "pages/process/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _83dfe29a,
    name: "account___fa",
    children: [{
      path: "infopacks",
      component: _f7b6fea0,
      name: "account-infopacks___fa"
    }, {
      path: "notifications",
      component: _649da80c,
      name: "account-notifications___fa"
    }, {
      path: "processes",
      component: _24244201,
      name: "account-processes___fa"
    }, {
      path: "transactions",
      component: _17fc993e,
      name: "account-transactions___fa"
    }, {
      path: "wallet",
      component: _11c81305,
      name: "account-wallet___fa"
    }, {
      path: "management/withdraw",
      component: _19e0227a,
      name: "account-management-withdraw___fa"
    }]
  }, {
    path: "/category-list",
    component: _2044fa56,
    name: "category-list___fa"
  }, {
    path: "/chat",
    component: _02270576,
    name: "chat___fa"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/error",
    component: _e6e8cede,
    name: "error___fa"
  }, {
    path: "/forgot",
    component: _22604260,
    name: "forgot___fa"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login___fa"
  }, {
    path: "/notfound",
    component: _c613de88,
    name: "notfound___fa"
  }, {
    path: "/oops",
    component: _0d29c7e0,
    name: "oops___fa"
  }, {
    path: "/register",
    component: _cc080460,
    name: "register___fa"
  }, {
    path: "/search",
    component: _6cfe81d6,
    name: "search___fa"
  }, {
    path: "/en/account",
    component: _83dfe29a,
    name: "account___en",
    children: [{
      path: "infopacks",
      component: _f7b6fea0,
      name: "account-infopacks___en"
    }, {
      path: "notifications",
      component: _649da80c,
      name: "account-notifications___en"
    }, {
      path: "processes",
      component: _24244201,
      name: "account-processes___en"
    }, {
      path: "transactions",
      component: _17fc993e,
      name: "account-transactions___en"
    }, {
      path: "wallet",
      component: _11c81305,
      name: "account-wallet___en"
    }, {
      path: "management/withdraw",
      component: _19e0227a,
      name: "account-management-withdraw___en"
    }]
  }, {
    path: "/en/category-list",
    component: _2044fa56,
    name: "category-list___en"
  }, {
    path: "/en/chat",
    component: _02270576,
    name: "chat___en"
  }, {
    path: "/en/error",
    component: _e6e8cede,
    name: "error___en"
  }, {
    path: "/en/forgot",
    component: _22604260,
    name: "forgot___en"
  }, {
    path: "/en/login",
    component: _cad79e9c,
    name: "login___en"
  }, {
    path: "/en/notfound",
    component: _c613de88,
    name: "notfound___en"
  }, {
    path: "/en/oops",
    component: _0d29c7e0,
    name: "oops___en"
  }, {
    path: "/en/register",
    component: _cc080460,
    name: "register___en"
  }, {
    path: "/en/search",
    component: _6cfe81d6,
    name: "search___en"
  }, {
    path: "/forgot/resetPassword",
    component: _1618ddf0,
    name: "forgot-resetPassword___fa"
  }, {
    path: "/forgot/verifyCode",
    component: _5ddf18f8,
    name: "forgot-verifyCode___fa"
  }, {
    path: "/payment/failed",
    component: _1490b7d0,
    name: "payment-failed___fa"
  }, {
    path: "/payment/success",
    component: _1f9f5100,
    name: "payment-success___fa"
  }, {
    path: "/register/userInfo",
    component: _1f92080b,
    name: "register-userInfo___fa"
  }, {
    path: "/register/verifyCode",
    component: _97234c10,
    name: "register-verifyCode___fa"
  }, {
    path: "/user/reset",
    component: _4d618ab8,
    name: "user-reset___fa"
  }, {
    path: "/verify/failed",
    component: _18c2ad48,
    name: "verify-failed___fa"
  }, {
    path: "/verify/success",
    component: _a8351ccc,
    name: "verify-success___fa"
  }, {
    path: "/en/forgot/resetPassword",
    component: _1618ddf0,
    name: "forgot-resetPassword___en"
  }, {
    path: "/en/forgot/verifyCode",
    component: _5ddf18f8,
    name: "forgot-verifyCode___en"
  }, {
    path: "/en/payment/failed",
    component: _1490b7d0,
    name: "payment-failed___en"
  }, {
    path: "/en/payment/success",
    component: _1f9f5100,
    name: "payment-success___en"
  }, {
    path: "/en/register/userInfo",
    component: _1f92080b,
    name: "register-userInfo___en"
  }, {
    path: "/en/register/verifyCode",
    component: _97234c10,
    name: "register-verifyCode___en"
  }, {
    path: "/en/user/reset",
    component: _4d618ab8,
    name: "user-reset___en"
  }, {
    path: "/en/verify/failed",
    component: _18c2ad48,
    name: "verify-failed___en"
  }, {
    path: "/en/verify/success",
    component: _a8351ccc,
    name: "verify-success___en"
  }, {
    path: "/en/profile/:username?",
    component: _6f5c647f,
    name: "profile-all___en"
  }, {
    path: "/en/infopacks/:token?/:infopackSlugOrTabToken?/:tabSlug?/:cat?",
    component: _54b44abc,
    name: "infopacks-all___en"
  }, {
    path: "/en/management/:infopackId?/:infoType?/:contentType?/:tabId?",
    component: _3834e206,
    name: "management-all___en"
  }, {
    path: "/en/process/:token?/:processSlugOrTabToken?/:tabSlugOrTasks?/:taskIdOrCat?",
    component: _05a46676,
    name: "process-all___en"
  }, {
    path: "/profile/:username?",
    component: _6f5c647f,
    name: "profile-all___fa"
  }, {
    path: "/infopacks/:token?/:infopackSlugOrTabToken?/:tabSlug?/:cat?",
    component: _54b44abc,
    name: "infopacks-all___fa"
  }, {
    path: "/management/:infopackId?/:infoType?/:contentType?/:tabId?",
    component: _3834e206,
    name: "management-all___fa"
  }, {
    path: "/process/:token?/:processSlugOrTabToken?/:tabSlugOrTasks?/:taskIdOrCat?",
    component: _05a46676,
    name: "process-all___fa"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___fa"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
