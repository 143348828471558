import { del, get, post, put } from '@/Services/Api/axios';
import { apiConfig } from '@/Services/helper';
import { Context } from '@nuxt/types';
// import { Context } from '@nuxt/types'
// import { Route, context } from "vue-router"
import qs from 'query-string';

export class DB {
    ctx: Context;
    private dbUrl: string = '';
    private baseApiVersionUrl: string = apiConfig.version;
    private baseApiPrefix: string = apiConfig.prefix;

    public setLanguage() {
        // this.headers["Accept-Language"] = language
    }

    protected async get(tableName: string, params: object = {}, query: object = {}): Promise<any> {
        return await get(
            qs.stringifyUrl({
                url: `${this.dbUrl}${this.baseApiPrefix}${this.baseApiVersionUrl}${tableName}`,
                query,
            }),
            params,
            {}
        );
    }

    protected async post(
        tableName: string,
        data: any,
        query: object = {},
        headers: object = {},
        config: object = {}
    ): Promise<any> {
        return await post(
            `${this.dbUrl}${this.baseApiPrefix}${this.baseApiVersionUrl}${tableName}`,
            data,
            { ...headers },
            query,
            config
        );
    }

    protected async postRaw(
        route: string,
        data: any,
        query: object = {},
        headers: object = {},
        config: object = {}
    ): Promise<any> {
        return await post(route, data, { ...headers }, query, config);
    }

    protected async put(
        tableName: string,
        data: any,
        query: object = {},
        headers: object = {},
        config: object = {}
    ): Promise<any> {
        return await put(
            `${this.dbUrl}${this.baseApiPrefix}${this.baseApiVersionUrl}${tableName}`,
            data,
            { ...headers },
            query,
            config
        );
    }

    protected async delete(tableName: string, headers: object = {}): Promise<any> {
        return await del(
            `${this.dbUrl}${this.baseApiPrefix}${this.baseApiVersionUrl}${tableName}`,
            {
                ...headers,
            }
        );
    }
}
