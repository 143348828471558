import { render, staticRenderFns } from "./Mobile.vue?vue&type=template&id=187517bd&scoped=true&"
import script from "./Mobile.vue?vue&type=script&lang=ts&"
export * from "./Mobile.vue?vue&type=script&lang=ts&"
import style0 from "./Mobile.vue?vue&type=style&index=0&id=187517bd&scoped=true&lang=scss&"
import style1 from "./Mobile.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187517bd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderSearch: require('/app/components/header/Search.vue').default,ILogo: require('/app/components/i/logo/index.vue').default,ProfileAvatarMenu: require('/app/components/profile/avatar-menu/index.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VBtn,VIcon})
