export enum languagesCode {
    english = 'en',
    persian = 'fa',
}

export type LanguageType = {
    lang: languagesCode;
    displayText: string;
    isRtl: boolean;
};

export const languages: LanguageType[] = [
    {
        lang: languagesCode.english,
        displayText: 'English',
        isRtl: false,
    },
    {
        lang: languagesCode.persian,
        displayText: 'فارسی',
        isRtl: true,
    },
];

export const appConfig = {
    defaultContentType: 'description',
    defaultLanguage: languagesCode.persian,
    infopack: {
        limit: 10,
    },
};

export const apiConfig = {
    version: '/v1',
    prefix: '/api',
};

// @TODO: should be get from config service
export const Values: any = {
    [languagesCode.english]: {
        language: 'Language',
        aboutus: 'About us',
        home: 'Home',
        close: 'close',
        login: 'Login',
        secondLogin: 'LOGIN',
        profile: 'Profile',
        privacy: 'Privacy',
        menu: 'Content list',
        chat: 'chat',
        users: 'Users',
        text: 'Description',
        video: 'Videos',
        image: 'Images',
        file: 'Files',
        link: 'Links',
        faq: 'FAQ',
        noDesc: 'There is no description.',
        dayAgo: 'days ago',
        view: 'view',
        phone: 'Phone',
        emailProfile: 'Email Address',
        download: 'download',
        welcomeSignin: 'Welcome to InfoDeniz',
        welcomeSignInDesc: 'whatever you want in the ocean of information!',
        signinAccount: 'Sing into your account',
        emailOrPhone: 'Email or phone number',
        phoneOrEmailHint: "For cellphone number don't enter the country code",
        email: 'Email',
        phoneNumber: 'phone number',
        phoneHint: "For cellphone number don't enter the country code",
        emailHint: 'enter your email',
        password: 'Password',
        newPassword: 'Type your new password',
        confirmPassword: 'Re-type your password',
        sendSmsMessage: 'we will send you a one time SMS message or email',
        sendSmsPhone: 'we will send you a one time SMS message',
        sendSmsEmail: 'we will send you a email',
        nextBtn: 'next',
        firstName: 'fist name',
        lastName: 'last name',
        forgetPassTitle: 'forget password',
        createNewAccount: 'create new account',
        forgetPassword: 'Forget password?',
        noAccount: "Don't have an account? ",
        createAccount: 'Sign Up',
        btnSingin: 'SIGN IN',
        btnResetPassword: 'RESET PASSWORD',
        invalidPhone: 'Phone number is not valid',
        invalidEmail: 'Email address or phone number is not valid',
        fieldCannotEmpty: 'can not be empty',
        fieldIsTooLong: 'can not be longer than LENGTH characters',
        passwordLength: 'Use 8 characters or more for your password',
        changePasswordConfirmError: 'values are not equal',
        verificationCode: 'type the verification code',
        doNotReciveCode: "I didn't receive the code",
        ReSendCode: 'Re-Send',
        submitBtn: 'SUBMIT',
        search: 'Search',
        searchResult: 'Search Result',
        changeNumber: 'change number or email',
        searchInput: 'search for ...',
        searchError: 'type something',
        searchResultError: 'no found result',
        back: 'Go back',
        orientationErrorMessage: 'Please rotate the screen vertically',
        deleteFilter: 'Delete search filter',
        logout: 'Logout',
        viewProfile: 'View Profile',
        contactInfo: 'Contact info',
        personalSetting: 'Personal Setting',
        verified: 'Verified',
        NotVerified: 'Not Verified',
        notSet: 'Not Set',
        userStatus: 'Type your status here...',
        userStatusHint: '(define your status)',
        pesonalData: 'personal data',
        cancel: 'Cancel',
        saveBtn: 'save changes',
        successLogin: 'login was successful',
        successSignup: 'signup was successful',
        errorConfirmPassword: 'make sure your passwords match',
        successChangePassword: 'change password was successful',
        globalMessage: 'save changes was successful',
        message: 'Message',
        editStatus: 'Edit',
        save: 'save',
        send: 'send',
        messageText: 'write a message ...',
        loginError: 'please login first',
        singupBtn: 'sign up',
        cropImage: 'Crop image',
        GetStarted: 'Get Started',
        Business: 'Business',
        ContentCreator: 'Content Creator',
        infodeniz: 'infodeniz',
        categoryName: 'category',
        categoryAll: 'all',
        placeholderSearch: 'what do you looking for?',
        moreResults: 'More results',
        allMessages: 'All Messages',
        infopackMsgs: 'Infopack Msgs',
        messaging: 'Messaging',
        more: 'more',
        less: 'less',
        haveNewMessage: 'New message',
        jobOpportunity: 'Job Opportunity',
        jobOpportunitySubtitle: 'For those who seek job around the world',
        bestHomeSection: {
            headerTitle: 'Top Infopacks',
            headerDescription: "New updated info you'll love and get help from it.",
            introduction: {
                title: 'Share your knowledge as Infopacks.   ',
                description:
                    "Infodeniz is an all-in-one platform where you can find information about migration and it's requirements.",
                whyTitle: 'Why organizations choose Infodeniz:',
                whyItems: {
                    one: 'Easy-to-use and easy to understand',
                    two: 'Access to the community ready to migrate',
                    three: 'Faster finding targeted audience',
                    four: 'Freemium and No annoying ads',
                },
                whyMore: 'Learn more about how Infodeniz works',
            },
        },
        mostView: {
            title: 'Trending',
            description: 'This week must popular Infopacks',
        },
        news: {
            title: 'New',
            description: 'Newly added Infopacks from our community',
        },
        exploreAll: 'Explore All',
        becomeMember: 'Become a Member',
        emailAddress: 'Email Address',
        subscribeText: 'Subscribe to be notified of new InfoPacks',
        aboutUs: 'About Us',
        contactUs: 'Contact info',
        result: 'result',
    },
    [languagesCode.persian]: {
        language: 'تغییر زبان',
        home: 'خانه',
        close: 'بستن',
        login: 'ورود/عضویت',
        secondLogin: 'ورود',
        profile: 'پروفایل',
        privacy: 'حریم خصوصی',
        aboutus: 'درباره ما',
        menu: 'منوها',
        chat: 'چت',
        users: 'کاربران',
        text: 'توضیحات',
        video: 'فیلم‌ها',
        image: 'عکس‌ها',
        file: 'فایل‌های مفید',
        link: 'لینک‌های ‌مفید',
        faq: 'سوالات متداول',
        noDesc: 'توضیحاتی وجود ندارد.',
        dayAgo: 'روز پیش',
        view: 'مشاهده',
        phone: 'شماره همراه',
        emailProfile: 'آدرس ایمیل',
        download: 'دانلود',
        welcomeSignin: 'به اینفودنیز خوش آمدید',
        welcomeSignInDesc: 'به اقیانوسی از اطلاعات دسترسی داشته باشید',
        signinAccount: 'وارد شدن به حساب کاربری',
        phoneOrEmailHint: 'شماره همراه خود را بدون کد کشور وارد نمایید',
        emailOrPhone: 'ایمیل یا شماره همراه',
        email: 'ایمیل',
        phoneNumber: 'ایمیل یا شماره همراه',
        phoneHint: 'شماره همراه خود را بدون کد کشور وارد نمایید',
        emailHint: 'ایمیل خود را وارد نمایید',
        password: 'رمزعبور',
        createNewAccount: 'ساخت اکانت جدید',
        forgetPassTitle: 'فراموشی رمز عبور',
        sendSmsMessage: 'کد تایید به ایمیل یا شماره تلفن شما ارسال خواهد شد',
        sendSmsPhone: 'کد تایید به شماره تلفن شما ارسال میشود',
        sendSmsEmail: 'کد تایید به ایمیل شما ارسال میشود',
        nextBtn: 'بعدی',
        firstName: 'نام',
        lastName: 'نام‌خانوادگی',
        newPassword: 'رمزعبور جدید',
        confirmPassword: 'تکرار رمزعبور',
        forgetPassword: 'رمزعبور خود را فراموش کرده‌اید؟',
        noAccount: 'حساب کاربری ندارید؟',
        createAccount: 'ایجاد حساب کاربری',
        btnResetPassword: 'تغییر رمزعبور',
        btnSingin: 'ورود',
        invalidPhone: 'شماره موبایل صحیح نیست',
        invalidEmail: 'فرمت آدرس ایمیل و یا شماره تلفن همراه صحیح نیست',
        fieldCannotEmpty: 'نمی‌تواند خالی باشد',
        fieldIsTooLong: 'نمی‌تواند بیشتر از LENGTH کاراکتر باشد',
        passwordLength: 'رمز عبور میبایست حداقل ۸ کاراکتر باشد',
        changePasswordConfirmError: 'مقادیر برابر نمی‌باشند',
        verificationCode: 'کد تایید را وارد نمایید',
        doNotReciveCode: 'کد را دریافت نکرده ام',
        ReSendCode: 'ارسال مجدد',
        submitBtn: 'ارسال',
        search: 'جستجو',
        searchResult: 'نتایج جستجو',
        changeNumber: 'تغییر شماره همراه یا ایمیل',
        searchInput: 'جستجو ...',
        searchError: 'چیزی تایپ کنید',
        searchResultError: 'نتیجه ای یافت نشد',
        back: 'بازگشت',
        orientationErrorMessage: 'لطفا صفحه را به حالت عمودی بچرخانید',
        deleteFilter: 'حذف فیلتر سرچ',
        logout: 'خروج',
        viewProfile: 'مشاهده پروفایل',
        contactInfo: 'اطلاعات تماس',
        personalSetting: 'تنظیمات شخصی',
        verified: 'تایید شده',
        NotVerified: 'تایید نشده',
        notSet: 'تنظیم نشده',
        userStatus: 'وضعیت خود را اینجا بنویسید..',
        userStatusHint: 'تعریف وضعیت',
        pesonalData: 'اطلاعات شخصی',
        cancel: 'انصراف',
        saveBtn: 'اعمال تغییرات',
        successLogin: 'ورود موفقیت آمیز بود',
        successSignup: 'ثبت نام با موفقیت انجام شد',
        successChangePassword: 'تغییر پسورد با موفقیت انجام شد',
        errorConfirmPassword: 'رمز عبور و تکرار آن برابر نیست',
        globalMessage: 'با موفقیت انجام شد',
        message: 'پیام',
        editStatus: 'ویرایش',
        save: 'ذخیره',
        send: 'ارسال',
        messageText: 'متن پیام را بنویسید ...',
        loginError: 'لطفا ابتدا ورود یا ثبت نام کنید',
        singupBtn: 'ثبت نام',
        cropImage: 'عکس خود را ببرید!',
        GetStarted: 'شروع کنید',
        Business: 'کسب و کار',
        ContentCreator: 'ایجادکننده محتوا',
        infodeniz: 'اینفودنیز',
        categoryName: 'دسته بندی ها',
        categoryAll: 'همه',
        placeholderSearch: 'دنبال چی میگردی؟',
        moreResults: 'نمایش بیشتر',
        allMessages: 'تمامی پیام‌ها',
        infopackMsgs: 'پیام‌های اینفوپک',
        messaging: 'پیام‌ها',
        more: 'بیشتر',
        less: 'کمتر',
        haveNewMessage: 'یک پیام جدید',
        jobOpportunity: 'فرصت‌های شغلی',
        jobOpportunitySubtitle: 'برای آنهایی که به دنبال موقعیت در خارج مرزها هستند',
        result: 'نتیجه',
        bestHomeSection: {
            headerTitle: 'برترین اینفوپک‌ها',
            headerDescription: 'اطلاعات بروز و کامل برای شما که به دنبال بهترین‌ها هستید. ',
            introduction: {
                title: 'منسجم ترین اطلاعات مهاجرت را در « اینفودنیز » ببینید.',
                description:
                    'در اینفودنیز میتوانید با وکلا، مهاجرین، و با همه افراد باتجربه ی مهاجرت، ارتباط بگیرید. کافیست ثبت نام کنید.',
                whyTitle: 'چرا موسسات از اینفودنیز استفاده می‌کنند:',
                whyItems: {
                    one: 'منسجم بودن اطلاعات',
                    two: 'آسان بودن رابط کاربری و یادگیری سریع',
                    three: 'ارتباط با جامعه ای از افراد آماده مهاجرت و امکان پرسش و پاسخ',
                    four: 'دریافت اطلاعات جامع کار و زندگی، از صفر تا 100 مهاجرت در ایمیل خودتان',
                },
                whyMore: 'برای مطالعه بیشتر در مورد نحوه کار اینفودنیز کلیک کنید ',
            },
        },
        mostView: {
            title: 'پر بازدیدترین',
            description: 'محبوب‌ترین اینفوپک‌های هفته‌ی اخیر',
        },
        news: {
            title: 'جدیدترین',
            description: 'اینفوپک‌هایی که به تازگی توسط موسسات منتشر شده‌اند',
        },
        exploreAll: 'مشاهده همه',
        becomeMember: 'ثبت‌نام',
        emailAddress: 'آدرس ایمیل',
        subscribeText: 'برای اطلاع از جدیدترین اینفوپک‌ها ایمیل خود را وارد نمایید',
        aboutUs: 'درباره ما',
        contactUs: 'تماس با ما',
    },
};

export const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }

    return 'unknown';
};

export const getDeviceType = (): string => {
    // const ua = navigator.userAgent;
    if (
        // /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)
        window.innerWidth <= 960 &&
        window.innerWidth > 960
    ) {
        return 'tablet';
    }
    if (
        // /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua) ||
        window.innerWidth < 960
    ) {
        return 'mobile';
    }
    return 'desktop';
};
