import { IP } from '@/Services/ServiceOwners/IP';
import { AxiosResultType } from '@/Services/Types/AxiosResultType';

export class PaymentService extends IP {
    private static _instance: PaymentService;
    private paymentRoute = '/ua/transaction';

    public static getInstance(): PaymentService {
        if (this._instance) {
            return this._instance;
        } else {
            this._instance = new PaymentService();
            return this._instance;
        }
    }

    async getHistory(limit: number, offset: number): Promise<any> {
        let historyRoute = `${this.paymentRoute}/history?offset=${offset}&limit=${limit}&status[]=2&status[]=3`;

        try {
            const result: AxiosResultType = await this.get(historyRoute);
            return result as any;
        } catch (e) {
            return e.message;
        }
    }

    public async increaseWallet(amount: string): Promise<any> {
        let route = this.paymentRoute + '/pay';
        const IR_CURRENCY = 4;
        const ZARINPAL_GATEWAY = 1;
        return await this.post(route, { amount, currency: IR_CURRENCY, gateway: ZARINPAL_GATEWAY });
    }

    public async withdraw(amount: number, IBAN?: string): Promise<any> {
        let route = this.paymentRoute + '/withdraw';
        const IR_CURRENCY = 4;
        const data = { amount, currency: IR_CURRENCY };
        if (IBAN) {
            data['IBAN'] = IBAN;
        }

        return await this.post(route, data);
    }
}
