



























































import { Vue, Component, Watch } from 'nuxt-property-decorator';
import IncreaseWalletVue from './IncreaseWallet.vue';
import { LanguageService } from '~/Services/LanguageServices';
import { ChatService } from '~/Services/ChatService';

@Component({
    components: { IncreaseWalletVue },
})
export default class HeaderMobile extends Vue {
    activeTab: string = '';
    searchToggle: boolean = false;
    lang: string = this.$route.params.lang ?? 'fa';
    showWalletModal = false;

    @Watch('$route')
    onUrlChange(nextValue, oldValue) {
        if (nextValue.query.searchMode && !oldValue.query.searchMode) {
            this.searchToggle = true;
        } else if (!nextValue.query.searchMode && oldValue.query.searchMode) {
            this.searchToggle = false;
        }
    }

    onSearchClick() {
        // this.$router.push({
        //   query: {
        //     searchMode: true,
        //   },
        // });
    }

    get unreadMessages() {
        return this.$store.getters['chatStore/getUnreadMessagesCount'];
    }

    async newMessages() {
        const chatService = new ChatService();
        const response = await chatService.getUserNewMessages();
        if (response.status === 200 && response?.data) {
            const newMessages = response?.data?.reduce((total, item) => {
                total += item.newMessageCount;
                return total;
            }, 0);

            this.$store.dispatch('chatStore/setUnreadMessages', {
                type: 'TOTAL',
                totalNewMessages: newMessages,
                data: response.data,
            });
        }
    }

    get currentLanguage() {
        return LanguageService.getCurrentLanguage(this);
    }
    get userInfo() {
        return this.$store.state.auth.user;
    }

    logout() {
        this.$logout();
    }

    get isInfopackDetail() {
        return this.$store.getters.getIsInfoPackDetailPage;
    }

    created() {
        if (this.$auth.loggedIn) {
            this.newMessages();
        }
        this.$i18n.onLanguageSwitched = (newLocale) => {
            newLocale !== 'fa' ?? '' ? (this.$vuetify.rtl = true) : (this.$vuetify.rtl = false);
        };
    }

    toggleWalletModal() {
        this.showWalletModal = !this.showWalletModal;
    }

    get languages(): any {
        const languageService = new LanguageService();
        return languageService.getLanguages();
    }

    getSelectedLanguage(value: any) {
        const selectedLanguage = this.languages.find((item: any) => {
            return item.lang === value;
        });
        return selectedLanguage.lang;
    }

    openProfile() {
        this.$router.push(this.localePath('/profile'));
    }

    openChat() {
        if (this.userInfo.phoneVerified || this.userInfo.emailVerified) {
            this.$router.push(this.localePath('/chat'));
        } else if (!this.userInfo.phoneVerified && !this.userInfo.emailVerified) {
            this.$store.dispatch('setSnackBar', {
                toggle: true,
                message: this.$t('info.messages.verifyAccount'),
                color: 'red',
            });
        }
    }
}
