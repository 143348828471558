import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=7abe6e08&scoped=true&"
import script from "./mobile.vue?vue&type=script&lang=js&"
export * from "./mobile.vue?vue&type=script&lang=js&"
import style0 from "./mobile.vue?vue&type=style&index=0&id=7abe6e08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7abe6e08",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ILogo: require('/app/components/i/logo/index.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VContainer,VDivider,VIcon})
