const state = {
    unreadMessages: {},
};

const getters = {
    getUnreadMessagesCount: (state) => state.unreadMessages?.totalNewMessages,
};

const actions = {
    setUnreadMessages({ commit }, payload) {
        commit('UPDATE_UNREAD_MESSAGES', payload);
    },
};

const mutations = {
    UPDATE_UNREAD_MESSAGES(state, payload) {
        if (payload.type === 'TOTAL') {
            state.unreadMessages = payload;
        } else if (payload.type === 'DECREASE' && state.unreadMessages.data) {
            const data = state.unreadMessages.data.map((item) => {
                if (item.roomId === payload.roomId) {
                    return item.newMessageCount;
                } else {
                    return 0;
                }
            })[0];
            if (data > 0) {
                state.unreadMessages = {
                    ...state.unreadMessages,
                    totalNewMessages: state.unreadMessages.totalNewMessages - data,
                };
            }
        } else if (payload.type === 'INCREASE') {
            state.unreadMessages = {
                ...state.unreadMessages,
                totalNewMessages: state.unreadMessages.totalNewMessages + 1,
            };
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
