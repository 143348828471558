























































































































































































import { Vue, Component } from 'nuxt-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import { SearchService } from '~/Services/SearchService';
import { SearchType } from '~/Services/Schemas/ISearch';
import { SocketSdk } from '@/Services/Socket/socket-sdk';

@Component({
    computed: {
        ...mapGetters({
            mostSearchedItems: 'getMostVisited',
        }),
        ...mapGetters('search', { mobileSearchPanel: 'mobileSearchPanel' }),
    },
    watch: {
        mobileSearchPanel(v) {
            this.searchPanel = v;
        },
    },
    methods: {
        ...mapActions('search', { setMobileSearchPanel: 'setMobileSearchPanel' }),
    },
})
export default class HeaderSearch extends Vue {
    result = [];
    searchPanel: Boolean = false;

    tabs = [
        { key: 'content', text: this.$t('info.public.infopack') },
        { key: 'process', text: this.$t('info.public.process') },
        { key: 'user', text: this.$t('search.user') },
    ];

    registerCreateConversationCb = undefined;

    si = undefined;

    selectedTab = 'content';

    loading = false;

    selectedItem = '';

    active: boolean = false;

    searchInput: string = '';

    timerId = undefined;

    showDropDownMenu = false;

    get highlight() {
        return (title) =>
            title.replace(new RegExp(this.searchInput, 'gi'), (match) => {
                return '<span class="weight-bold info-black">' + match + '</span>';
            });
    }

    onshowMoreSearchItems() {
        this.cancelSearch();
        this.$router.push({
            path: this.localePath('/search'),
            query: {
                s: this.searchInput,
                c: this.selectedTab,
            },
        });
    }

    mounted() {
        this.si = SocketSdk.getInstance(
            this.$auth.strategy.token.get(),
            this.$i18n.localeProperties.code.toUpperCase()
        );
        this.openDropDownMenu();
    }

    destroyed() {
        if (this.registerCreateConversationCb) {
            this.registerCreateConversationCb();
        }
        this.cancelSearch();
    }

    onContentClick(infopack) {
        this.cancelSearch();
        this.searchInput = infopack.title;
        const query = `/${this.selectedTab === 'content' ? 'infopacks' : 'process'}/${
            infopack.token
        }`;
        this.$router.push(this.localePath(query));
    }

    onBusinessClick(item) {
        this.cancelSearch();
        this.searchInput = item.businessName;
        if (item.profileUrl) {
            this.$router.push(this.localePath(item.profileUrl));
        }
    }

    onUserClick(people) {
        if (!this.$auth.loggedIn) {
            this.$store.dispatch('setSnackBar', {
                toggle: true,
                message: this.$t('search.chatError'),
                color: 'red',
            });
            return;
        }
        this.cancelSearch();
        this.searchInput = people.title;
        const startConversationDto = {
            receiverId: people.id,
            type: 'conversation',
            firstName: people.firstName,
            lastName: people.lastName,
        };

        this.si.sendCreateConversationEvent(startConversationDto);

        this.registerCreateConversationCb = this.si.registerCreateConversationCbEvent((data) => {
            this.$router.push(this.localePath('/chat'));
        });
    }

    debounceFunction(func, delay) {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(func, delay);
    }

    async getData() {
        this.loading = true;
        this.result = [];

        const searchSVC = SearchService.getInstance();
        const data = await searchSVC.search({
            type: this.selectedTab as SearchType,
            offset: 0,
            limit: 5,
            search: this.searchInput,
        });

        if (this.selectedTab === 'user' && data.docs && data.docs.length) {
            data.docs = data.docs.map((item) => {
                const firstName = item.firstName;
                const job =
                    item.experiences && item.experiences.length
                        ? item.experiences[0].title
                        : this.$t('search.noJob');
                const targets =
                    item.destinations && item.destinations.length
                        ? item.destinations.join(', ')
                        : this.$t('search.noTarget');
                return {
                    title: `${firstName} - ${job} - ${targets}`,
                    ...item,
                };
            });
        }

        this.result = data.docs || [];
        this.loading = false;
    }

    onTabChange(selectedItem) {
        if (this.selectedTab !== selectedItem.key) {
            this.result = [];
            this.selectedTab = selectedItem.key;
            this.getData();
        }
    }

    openDropDownMenu() {
        this.showDropDownMenu = true;
    }

    searchValue(): any {
        this.loading = true;
        this.debounceFunction(this.getData, 500);
    }

    onInputClick(event) {
        event.stopPropagation();
        this.active = true;
        this.openDropDownMenu();
    }

    onMostSearchedItemClick(searchInput) {
        this.searchInput = searchInput;
        this.getData();
    }

    cancelSearch(): any {
        this.setMobileSearchPanel(false);
    }

    search(value: string): void {
        this.cancelSearch();
        this.$router.push({
            path: this.localePath('/infopack-list'),
            query: { search: value },
        });
    }
}
