//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            timer: null,
        };
    },
    computed: {
        ...mapGetters('snackbar', {
            largeSnackBar: 'largeSnackBar',
        }),
    },
    watch: {
        largeSnackBar(v) {
            if (v) {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    this.setLargeSnackBar({});
                }, 5000);
            }
        },
    },
    methods: {
        ...mapActions('snackbar', {
            setLargeSnackBar: 'setLargeSnackBar',
        }),
    },
};
