//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from '@/components/usersList/Modal.vue';
import { PaymentService } from '~/Services/PaymentService';
import commafy from '@/mixinsjs/commafy';

export default {
    components: {
        Modal,
    },
    mixins: [commafy],
    props: ['close'],
    computed: {
        userInfo() {
            return this.$store.state.auth.user;
        },
    },
    data() {
        return {
            loading: true,
            prices: [1000000, 2000000, 5000000],
            selectedAmount: '',
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        async onPaymentClick() {
            if (!this.selectedAmount) {
                this.$store.dispatch('setSnackBar', {
                    toggle: true,
                    message: 'لطفا یکی از مبالغ بالا را انتخاب کنید',
                    color: 'red',
                });
                return; // TODO show the snack bar
            }

            this.loading = true;
            const amount = this.selectedAmount;
            this.selectedAmount = undefined;
            const paymentSVC = PaymentService.getInstance();

            try {
                const res = await paymentSVC.increaseWallet(amount);

                document.location.href = res.data.url;
            } catch (error) {
                // TODO show snack bar
            }
            this.loading = false;
        },
    },
};

// [X] change the numbers
// [X] loading
// [X] show the real balance
// [] remove persian charachters
// [] change balance color if it is more that 0
