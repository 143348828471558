import moment from 'moment';
import jalaliMoment from 'jalali-moment';
import processService from '~/Services/process';
import infopackService from '~/Services/infopack';
import profileService from '~/Services/profile';
import homeService from '~/Services/home';
import userService from '~/Services/users';
import managementService from '~/Services/management-v2';
import paymentService from '~/Services/payment';
import NotificationService from '~/Services/notifications';
import subscriptionService from '~/Services/subscription';
import variables from '~/assets/json-collections/variables';
import withdrawService from '~/Services/withdraw';

export default (ctx, inject) => {
    // services injection
    processService.ctx = ctx;
    infopackService.ctx = ctx;
    profileService.ctx = ctx;
    homeService.ctx = ctx;
    managementService.ctx = ctx;
    subscriptionService.ctx = ctx;
    userService.ctx = ctx;
    paymentService.ctx = ctx;
    withdrawService.ctx = ctx;

    NotificationService.ctx = ctx;

    inject('processService', processService);
    inject('infopackService', infopackService);
    inject('profileService', profileService);
    inject('homeService', homeService);
    inject('managementService', managementService);
    inject('subscriptionService', subscriptionService);
    inject('userService', userService);
    inject('paymentService', paymentService);
    inject('withdrawService', withdrawService);
    inject('NotificationService', NotificationService);

    inject('moment', moment);
    inject('jalaliMoment', jalaliMoment);

    const commonVariables = {
        emailRegex: variables.emailRegex,
        mobileRegex: variables.mobileRegex,
        mobileWithCountryCodeRegex: variables.mobileWithCountryCodeRegex,
        urlRegex: variables.urlRegex,
        persianMonths: [
            'فروردین',
            'اردیبهشت',
            'خرداد',
            'تیر',
            'مرداد',
            'شهریور',
            'مهر',
            'آبان',
            'آذر',
            'دی',
            'بهمن',
            'اسفند',
        ],
        confirmationLinkTime: 120,
    };
    inject('commonVariables', commonVariables); // Inject in Vue, context and store.

    // constants and enums
    const Enums = Object.freeze({
        ProcessTaskStatus: {
            STARTED: 1,
            PROGRESSING: 2,
            DONE: 3,
        },
        DescriptionSavingStatus: {
            UNDEFINED: 0,
            SUCCESSFUL: 1,
            FAILED: 2,
            PENDING: 3,
        },
        ProcessUserStatus: {
            ORIGINAL: 1,
            COPIED: 2,
            SHARED: 3,
            RUNNING: 4,
            BOOKMARK: 5,
            REGISTERED: 6,
        },
        CompanySize: {
            MICRO: '1-9',
            SMALL: '10-49',
            MEDIUM: '50-249',
            LARGE: '250-500',
            GIANT: '501-1000',
            ENTERPRISE: '1001',
        },
        Roles: {
            ADMIN: 'admin',
            USER: 'user',
            BUSINESS: 'business',
        },
        SubscriptionStatusRef: {
            ACTIVE: 1,
            WAITING_APPROVAL: 2,
            WAITING_PAYMENT: 3,
            REJECTED: 4,
            WAITING_CANCELATION: 5,
            CANCELED: 6,
            DELETED: 7,
            FINISHED: 8,
        },
        PackType: {
            INFOPACK: 1,
            PROCESS: 2,
        },
        TransactionActionTarget: {
            SUBSCRIPTION: 1,
        },
        SubscriptionVersionType: {
            SHARED: 1,
            COPIED: 2,
        },
        ContentTypeNumeric: {
            TEXT: 1,
            IMAGE: 2,
            VIDEO: 3,
            LINK: 4,
            FAQ: 5,
            FILE: 6,
            AUDIO: 7,
        },
        PaymentType: {
            CHARGE: 1,
            ADVICE: 2,
            SUBSCRIPTION_BUY: 3,
            COMMISSION_DOWN: 4,
            COMMISSION_UP: 5,
            SUBSCRIPTION_SELL: 6,
            WITHDRAW: 7,
            1: 'CHARGE',
            2: 'ADVICE',
            3: 'SUBSCRIPTION_BUY',
            4: 'COMMISSION_DOWN',
            5: 'COMMISSION_UP',
            6: 'SUBSCRIPTION_SELL',
            7: 'WITHDRAW',
        },
    });
    inject('Enums', Enums); // Inject in Vue, context and store.
};
