


























































































































































































import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { mapGetters } from 'vuex';
import { LanguageService } from '~/Services/LanguageServices';
import { SearchService } from '~/Services/SearchService';
import { SearchType } from '~/Services/Schemas/ISearch';

import { SocketSdk } from '@/Services/Socket/socket-sdk';

@Component({
    components: {},
    computed: {
        ...mapGetters({
            mostSearchedItems: 'getMostVisited',
        }),
    },
})
export default class HeaderSearch extends Vue {
    result = [];

    tabs = [
        { key: 'content', text: this.$t('info.public.infopack') },
        { key: 'process', text: this.$t('info.public.process') },
        { key: 'user', text: this.$t('search.user') },
        // { key: 'business', text: 'موسسات' },
    ];

    registerCreateConversationCb = undefined;

    si = undefined;

    selectedTab = 'content';

    loading = false;

    selectedItem = '';

    active: boolean = false;

    searchInput: string = '';

    timerId = undefined;

    showDropDownMenu = false;

    searchMenuPosition = {
        top: '0px',
        left: '0px',
        width: '0px',
    };

    @Prop() mobileMode: boolean;
    // host: window.location.protocol + "//" + window.location.host,
    // lang: string = this.$route.params.lang ?? "fa"
    get currentLanguage() {
        return LanguageService.getCurrentLanguage(this);
    }

    get highlight() {
        return (title) =>
            title.replace(new RegExp(this.searchInput, 'gi'), (match) => {
                return '<span class="highlightText">' + match + '</span>';
            });
    }

    onshowMoreSearchItems() {
        this.cancelSearch();
        this.$router.push({
            path: this.localePath('/search'),
            query: {
                s: this.searchInput,
                c: this.selectedTab,
            },
        });
    }

    mounted() {
        this.si = SocketSdk.getInstance(
            this.$auth.strategy.token.get(),
            this.$i18n.localeProperties.code.toUpperCase()
        );
        this.openDropDownMenu();
    }

    destroyed() {
        if (this.registerCreateConversationCb) {
            this.registerCreateConversationCb();
        }
        this.cancelSearch();
    }

    onContentClick(infopack) {
        this.cancelSearch();
        this.searchInput = infopack.title;
        const query = `/${this.selectedTab === 'content' ? 'infopacks' : 'process'}/${
            infopack.token
        }`;
        this.$router.push(this.localePath(query));
    }

    onBusinessClick(item) {
        this.cancelSearch();
        this.searchInput = item.businessName;
        if (item.profileUrl) {
            this.$router.push(this.localePath(item.profileUrl));
        }
    }

    onUserClick(people) {
        if (!this.$auth.loggedIn) {
            this.$store.dispatch('setSnackBar', {
                toggle: true,
                message: this.$t('search.chatError'),
                color: 'red',
            });
            return;
        }
        this.cancelSearch();
        this.searchInput = people.title;
        const startConversationDto = {
            receiverId: people.id,
            type: 'conversation',
            firstName: people.firstName,
            lastName: people.lastName,
        };

        this.si.sendCreateConversationEvent(startConversationDto);

        this.registerCreateConversationCb = this.si.registerCreateConversationCbEvent((data) => {
            this.$router.push(this.localePath('/chat'));
        });
    }

    debounceFunction(func, delay) {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(func, delay);
    }

    async getData() {
        this.loading = true;
        this.result = [];

        const searchSVC = SearchService.getInstance();
        const data = await searchSVC.search({
            type: this.selectedTab as SearchType,
            offset: 0,
            limit: 5,
            search: this.searchInput,
        });

        if (this.selectedTab === 'user' && data.docs && data.docs.length) {
            data.docs = data.docs.map((item) => {
                const firstName = item.firstName;
                const job =
                    item.experiences && item.experiences.length
                        ? item.experiences[0].title
                        : this.$t('search.noJob');
                const targets =
                    item.destinations && item.destinations.length
                        ? item.destinations.join(', ')
                        : this.$t('search.noTarget');
                return {
                    title: `${firstName} - ${job} - ${targets}`,
                    ...item,
                };
            });
        }

        this.result = data.docs || [];
        this.loading = false;
    }

    onTabChange(selectedItem) {
        if (this.selectedTab !== selectedItem.key) {
            this.result = [];
            this.selectedTab = selectedItem.key;
            this.getData();
        }
    }

    openDropDownMenu() {
        const pos = this.$refs['text-field'].getBoundingClientRect();

        if (this.$breakpoint.mdAndUp) {
            this.searchMenuPosition = {
                top: `${pos.bottom + 2}px`,
                left: `${pos.left}px`,
                width: `${this.$refs['text-field'].offsetWidth}px`,
            };
        } else {
            this.searchMenuPosition = {
                height: 'calc(100vh - 48px)',
                width: '100vw',
                left: '0',
                top: '65px',
            };
        }
        this.showDropDownMenu = true;
    }

    searchValue(value: any): any {
        this.loading = true;
        this.debounceFunction(this.getData, 500);
    }

    onInputClick(event) {
        event.stopPropagation();
        this.active = true;
        this.openDropDownMenu();
    }

    onMostSearchedItemClick(searchInput) {
        this.searchInput = searchInput;
        this.getData();
    }

    closeMenu() {
        this.active = false;
        this.showDropDownMenu = false;
    }

    cancelSearch(): any {
        this.$emit('cancel');
    }

    search(value: string): void {
        this.cancelSearch();
        this.$router.push({
            path: this.localePath('/' + this.currentLanguage + '/infopack-list'),
            query: { search: value },
        });
    }
}

// [X] render inline no result
// [X] search in Mobile
// [] default businesses Icon in search result
// [] we need more information for people in search result
// [] on search item click per type
// [X] load more icon
// [] polish in desktop
// [X] render different type of search
// [X] clear icon search
// [] search page
// [X] handle device back button when user click on search Icon in mobile
// [X] connect to API
// [] remove persian charachters
// [X] real most visited items
// [] small components
// [] save search results in DB
// [] check tablet mode
// [X] clear search mode in query string in create cycle
// [X] set overflow hidden for main container
// [] we should search people just by education, target country. (API)
// [] focus input when click on search button
