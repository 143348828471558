























import { Vue, Component } from 'nuxt-property-decorator';
import { mapGetters, mapActions } from 'vuex';

@Component({
    computed: {
        ...mapGetters('sidebar', { showSidebar: 'showSidebar' }),
        loggedIn() {
            return this.$store.state.auth?.loggedIn;
        },
    },
    watch: {
        sidebar(v) {
            if (!v) {
                this.hideSidebar();
            }
        },
        showSidebar(v) {
            this.sidebar = v;
        },
    },
    methods: {
        ...mapActions('sidebar', { hideSidebar: 'hideSidebar' }),
    },
})
export default class Default extends Vue {
    header: string = '';
    sidebar: Boolean = false;

    head() {
        return {
            meta: [
                // hid is used as unique identifier. Do not use `vmid` for it as it will not work
                {
                    hid: 'canonical',
                    name: 'canonical',
                    content: `https://infodeniz.com${this.$route.fullPath}`,
                },
            ],
        };
    }

    created() {
        if (this.$route.query.searchMode) {
            const query = Object.assign({}, this.$route.query);
            delete query.searchMode;
            this.$router.replace({
                query,
            });
        }
    }
}
