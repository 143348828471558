


























































































































































import { mapActions } from 'vuex';
import commafy from '@/mixinsjs/commafy';

export default {
    name: 'Sidebar',
    mixins: [commafy],
    data() {
        return {
            showWalletModal: false,
            hasNotification: false,
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.auth.user;
        },
    },
    created() {
        this.notificationCount();
    },
    methods: {
        ...mapActions('sidebar', { hideSidebar: 'hideSidebar' }),
        logout() {
            this.hideSidebar();
            this.$logout();
        },
        async notificationCount(): Promise<void> {
            this.hasNotification = (await this.$NotificationService.findCount()) > 0;
        },
    },
};
