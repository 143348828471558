const servicePath = '/api/v1/ua';

export default {
    /**
     * @description getting the data of the user with specific username
     * @param username string
     * @returns any
     */
    async getUserByUsername(username: string): Promise<any> {
        try {
            const response = await this.ctx.$axios.$get(`${servicePath}/users/find/${username}`);
            return response.data;
        } catch (error) {
            throw error.response || error.notofication || error;
        }
    },

    /**
     * @description getting the credit of user
     * @returns any
     */
    async credit(): Promise<any> {
        try {
            const response = await this.ctx.$axios.$get(`${servicePath}/users/credit`);
            return response.data;
        } catch (error) {
            throw error.response || error.notofication || error;
        }
    },
};
