const state = {
    userProfileInfo: {},
    editProfile: false,
    editProfileInfo: false,
    editPhoneProfile: false,
    editEmailProfile: false,
    editProfileEducation: false,
    deleteProfileEducation: false,
    addProfileEducation: false,
    addProfileCertification: false,
    editProfileCertification: false,
    deleteProfileCertification: false,
    addProfileLanguage: false,
    editProfileLanguage: false,
    deleteProfileLanguage: false,
    addProfileImmigration: false,
    editProfileImmigration: false,
    deleteProfileImmigration: false,
    addProfileWork: false,
    editProfileWork: false,
    deleteProfileWork: false,
    selectedEducationIndex: -1,
    selectedWorkIndex: -1,
    selectedLanguageIndex: -1,
    selectedCertificationIndex: -1,
    selectedImmigrationIndex: -1,
    profileCampaign: false,
    selectedUserId: undefined,
};

const mutations = {
    getUserInfo(state, { userInfo }) {
        state.userProfileInfo = userInfo;
    },
    setSelectedUserId(state, { userId }) {
        state.selectedUserId = userId;
    },
    editprofileEducation(state, payload) {
        state.selectedEducationIndex = payload;
    },
    editprofileWork(state, payload) {
        state.selectedWorkIndex = payload;
    },
    editProfileLanguage(state, payload) {
        state.selectedLanguageIndex = payload;
    },
    editprofileCertification(state, payload) {
        state.selectedCertificationIndex = payload;
    },
    editProfileImmigration(state, payload) {
        state.selectedImmigrationIndex = payload;
    },
    setOTPRemainingTime(state, { isMobile, remainingTimeInSecond }) {
        if (isMobile) {
            state.userProfileInfo.otpSmsExpires = remainingTimeInSecond;
        } else {
            // email
            state.userProfileInfo.otpEmailExpires = remainingTimeInSecond;
        }
    },
    setResumeInfo(state, { fileName, fileURL }) {
        state.userProfileInfo.resume = fileURL;
        state.userProfileInfo.resumeOriginalFilename = fileName;
    },
};

const actions = {
    async getUserInfo(context, { userId = '' } = {}) {
        const { commit, state, rootState } = context;
        if ((state?.auth || rootState?.auth)?.loggedIn) {
            const userInfo = await this.$profileService.getUserProfileInfo(
                userId || state.selectedUserId
            );
            if (typeof userInfo === 'object') {
                commit('getUserInfo', { userInfo });
            }
            return userInfo;
        }
    },
    setSelectedUserId({ commit }, { userId = '' } = {}) {
        commit('setSelectedUserId', { userId });
    },

    setResumeInfo({ commit }, { fileName, fileURL }) {
        commit('setResumeInfo', { fileName, fileURL });
    },
    setOTPRemainingTime({ commit }, { isMobile, remainingTimeInSecond }) {
        commit('setOTPRemainingTime', { isMobile, remainingTimeInSecond });
    },
    editprofileEducation({ commit }, payload) {
        commit('editprofileEducation', payload);
    },
    editprofileWork({ commit }, payload) {
        commit('editprofileWork', payload);
    },
    editProfileLanguage({ commit }, payload) {
        commit('editProfileLanguage', payload);
    },
    editProfileCertification({ commit }, payload) {
        commit('editprofileCertification', payload);
    },
    editProfileImmigration({ commit }, payload) {
        commit('editProfileImmigration', payload);
    },
};

const getters = {
    getSelectedUserId: (state) => {
        return state.selectedUserId;
    },

    getUserResumeInfo: (state) => {
        let { userProfileInfo } = state;
        if (!userProfileInfo) {
            userProfileInfo = {};
        }

        const { resume, resumeOriginalFilename } = userProfileInfo;
        return {
            resume,
            resumeOriginalFilename,
        };
    },

    getProfileInfoModal: (state) => {
        let { userProfileInfo } = state;
        if (!userProfileInfo) {
            userProfileInfo = {};
        }

        let { recommendationInfo } = userProfileInfo;

        if (!recommendationInfo) {
            recommendationInfo = {};
        }

        let { personalInfo, current = {}, citizenship = [], educations = [] } = recommendationInfo;

        if (!personalInfo) {
            personalInfo = {};
        }

        if (!current) {
            current = {};
        }

        if (!citizenship) {
            citizenship = [];
        }

        if (!educations) {
            educations = [];
        }

        const { city = '' } = current;

        const { numberOfChildren, maritalStatus, gender, birthDate } = personalInfo;

        const {
            firstName,
            lastName,
            email,
            phone,
            otpSmsExpires,
            otpEmailExpires,
            phoneVerified,
            emailVerified,
            resume,
            resumeOriginalFilename,
            username,
        } = userProfileInfo;

        return {
            firstName: firstName || '',
            lastName: lastName || '',
            email: email || '',
            phone: phone || '',
            numberOfChildren: numberOfChildren || 0,
            maritalStatus: maritalStatus || '',
            gender,
            birthDate: birthDate || '',
            city: city || '',
            citizenship,
            educations,
            otpSmsExpires,
            otpEmailExpires,
            phoneVerified,
            emailVerified,
            resume,
            username,
            resumeOriginalFilename,
        };
    },

    getProfilePhoneModal: (state) => {
        let { userProfileInfo } = state;
        if (!userProfileInfo) {
            userProfileInfo = {};
        }

        const { phone } = userProfileInfo;

        return {
            phone: phone || '',
        };
    },

    getProfileEmailModal: (state) => {
        let { userProfileInfo } = state;
        if (!userProfileInfo) {
            userProfileInfo = {};
        }

        const { email } = userProfileInfo;

        return {
            email: email || '',
        };
    },

    getProfileScore: (state) => {
        let { userProfileInfo } = state;
        if (!userProfileInfo) {
            userProfileInfo = {};
        }

        const { profileScore } = userProfileInfo;

        return {
            profileScore: profileScore || '',
        };
    },

    getSelectedEducationIndex: (state) => {
        return state.selectedEducationIndex;
    },

    getProfileEducationModal: (state) => {
        let { selectedEducationIndex } = state;
        if (selectedEducationIndex > -1) {
            const userEducation =
                state.userProfileInfo.recommendationInfo.educations[selectedEducationIndex];
            let { current, degree, field, university, start, end } = userEducation;
            return {
                current,
                degree: degree || '',
                field: field || '',
                university: university || '',
                start: start || '',
                end: end || '',
            };
        }
        return {
            current: '',
            degree: '',
            field: '',
            university: '',
            start: '',
            end: '',
        };
    },

    getSelectedWorkIndex: (state) => {
        return state.selectedWorkIndex;
    },

    getProfileWorkModal: (state) => {
        let { selectedWorkIndex } = state;

        if (selectedWorkIndex > -1) {
            const userWork =
                state.userProfileInfo.recommendationInfo.experiences[selectedWorkIndex];
            const { title, company, start, end, current } = userWork;
            return {
                title: title || '',
                company: company || '',
                current,
                start: start || '',
                end: end || '',
            };
        }

        return {
            title: '',
            company: '',
            current: '',
            start: '',
            end: '',
        };
    },

    getProfileDestinationsModal: (state) => {
        let { selectedImmigrationIndex } = state;

        if (selectedImmigrationIndex > -1) {
            const userImmigration =
                state.userProfileInfo.recommendationInfo.destinations[selectedImmigrationIndex];
            const { country, visa, fund } = userImmigration;

            return {
                country,
                fund: fund || '',
                visa: visa || '',
            };
        }
        return {
            country: '',
            fund: '',
            visa: '',
        };
    },

    getSelectedCertificationIndex: (state) => {
        return state.selectedCertificationIndex;
    },

    getProfileCertificationsModal: (state) => {
        let { selectedCertificationIndex } = state;

        if (selectedCertificationIndex > -1) {
            const userCertification =
                state.userProfileInfo.recommendationInfo.generalCerts[selectedCertificationIndex];

            const { date, link, organization, title } = userCertification;
            return {
                date: date || '',
                link: link || '',
                organization: organization || '',
                title: title || '',
            };
        }

        return {
            date: '',
            link: '',
            organization: '',
            title: '',
        };
    },

    getSelectedLanguageIndex: (state) => {
        return state.selectedLanguageIndex;
    },

    getProfileLanguageModal: (state) => {
        let { selectedLanguageIndex } = state;

        if (selectedLanguageIndex > -1) {
            const userLanguage =
                state.userProfileInfo.recommendationInfo.languageCerts[selectedLanguageIndex];
            const { date, degree, language, level, score } = userLanguage;
            return {
                date,
                degree: degree || '',
                language: language || '',
                level: level || '',
                score: score || '',
            };
        }

        return {
            date: '',
            degree: '',
            language: '',
            level: '',
            score: '',
        };
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
