import VueI18n from 'vue-i18n';
import Vue from 'vue';
import axios from 'axios';
import en from '../locales/en.json';
import fa from '../locales/fa.json';
import { languagesCode } from '../../Services/helper';

export default {
    locales: [
        {
            code: 'en',
            file: 'en.json',
        },
        {
            code: 'fa',
            file: 'fa.json',
        },
    ],
    langDir: './helpers/locales/',
    defaultLocale: 'fa',
    lazy: true,
    detectBrowserLanguage: false,
    vueI18n: {
        fallbackLocale: 'fa',
        messages: {
            en,
            fa,
        },
    },
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: languagesCode.persian,
    // fallbackLocale: , can't be useful, becuase all values are get from server and there is no way to swtich to fallback language if some values are not provided
});

const loadedLanguages: any = [];

export function setI18nLanguage(lang: string) {
    // i18n.setLocaleMessage(lang, );
    axios.defaults.headers.common['Accept-Language'] = lang;
    return lang;
}

export function loadLanguageAsync(lang: languagesCode) {
    setI18nLanguage(lang);
    return Promise.resolve();
    // router.replace(`/${lang}`)
    // return axios.get(`https://api.mocki.io/v1/b043df5a`).then(response => {
    //   const msgs = response.data;
    //   msgs.push();
    //
    //   loadedLanguages.push(lang)
    //   const obFa= {
    //     menu: "منو"
    //   }
    //   const obEn = {
    //     menu: "menu"
    //   }
    //   i18n.setLocaleMessage(lang, lang === "fa" ? obFa : obEn)
    //   setI18nLanguage(lang)
    // })
}
