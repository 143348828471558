//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Modal',
    props: ['width', 'contentClass', 'fullscreen'],

    methods: {
        closeDialog() {
            this.$emit('closeModalUsers');
            this.$emit('closeModal');
        },
    },
};
