import { IP } from '@/Services/ServiceOwners/IP';
import ISearch from '@/Services/Schemas/ISearch';
import { AxiosResultType } from '@/Services/Types/AxiosResultType';

import { appConfig } from './helper';

export class SearchService extends IP {
    private static _instance: SearchService;

    private constructor() {
        super();
    }

    public static getInstance(): SearchService {
        if (this._instance) {
            return this._instance;
        } else {
            this._instance = new SearchService();
            return this._instance;
        }
    }

    private tableName = `${this.serviceOwner}/search-service`;

    async search(params: ISearch): Promise<any> {
        const route = this.tableName;
        const data: AxiosResultType = await this.get(route, params);
        return data;
    }

    async getMostVisited(): Promise<any> {
        const route = `${this.tableName}/most-searched?limit=10&offset=0`;
        const data: AxiosResultType = await this.get(route);
        return data;
    }
}
