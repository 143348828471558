import { IUploadAsset } from './interfaces';
import { URI } from '@/utils/uri';
import { ApiConfig } from '@/config/api.config';
import { ApiResponse } from '@/utils/interfaces';

const servicePath = '/api/v1/ip';
const gatewayPath = '/gateway';

export default {
    async updateDetails(id: string, data: object): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}`;

            const response = await this.ctx.$axios.$put(route, data);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async getSubscriptionSetting(id: string): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${id}`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async addSubscriptionSetting(id: string, data: object): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${id}`;

            const response = await this.ctx.$axios.$post(route, data);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async editSubscriptionSetting(id: string, subscriptionId: string, data: object): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${id}/${subscriptionId}`;

            const response = await this.ctx.$axios.$put(route, data);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async share(infopackId: number, data: object): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${infopackId}/share`;

            const response = await this.ctx.$axios.$post(route, data);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async copy(infopackId: number, data: object): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${infopackId}/copy`;

            const response = await this.ctx.$axios.$post(route, data);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async getHealthStatus(id: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/publish/health`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async delete(id: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}`;

            const response = await this.ctx.$axios.$delete(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async revokeAccess(id: number): Promise<ApiResponse> {
        try {
            const route = `${servicePath}/management/infopacks/revokeAccess/${id}`;

            return (await this.ctx.$axios.$delete(route)).data;
        } catch (error: any) {
            throw error.response || error;
        }
    },

    async version(id: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/version`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async get(id: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/tabs`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async getTab(id: number, tabId: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/tabs/${tabId}`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async addTab(infopackId: number, data: object): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${infopackId}/tabs`;

            const response = await this.ctx.$axios.$post(route, data);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async deleteTab(id: number, tabId: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/tabs/${tabId}`;

            await this.ctx.$axios.$delete(route);
        } catch (error) {
            throw error.response || error;
        }
    },

    async putTab(id: number, tabId: number, data: object): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/tabs/${tabId}`;

            const response = await this.ctx.$axios.$put(route, data);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async getPublishSetting(id: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/publish/data`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async putPublishSetting(id: number, data: object): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/publish`;

            const response = await this.ctx.$axios.$put(route, data);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async upload(data: object, config: object): Promise<any> {
        try {
            const route = '/api/v1/file/file-upload/infopack-asset';

            const response = await this.ctx.$axios.$post(route, data, { ...config });
            return response as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async uploadPrivateAsset(data: object, config: object): Promise<any> {
        return this.uploadAsset({
            data,
            config,
            route: '/gateway/v1/file/upload/private',
        });
    },

    async uploadPublicAsset(data: object, config: object): Promise<any> {
        return this.uploadAsset({
            data,
            config,
            route: '/gateway/v1/file/upload/public',
        });
    },

    async uploadAsset(input: IUploadAsset): Promise<any> {
        try {
            return await this.ctx.$axios.$post(input.route, input.data, { ...input.config });
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description uploads the video and get its covers too!
     * @param data video data
     * @param config axios config to show the progress bar
     * @returns any
     */
    async uploadVideo(data: object, config: object): Promise<any> {
        try {
            const route = '/api/v1/file/file-upload/infopack-asset-video';

            const response = await this.ctx.$axios.$post(route, data, { ...config });
            return response as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async createPack(type: number) {
        try {
            const defaultData = {
                type,
                slug: '',
                subtitle: '',
                languageRef: 2,
                categoryRef: 2,
                description: '',
                title: '',
                tags: '',
                keywords: '',
                infopackAssetRef: null,
            };
            const route = `${servicePath}/management/infopacks`;

            const response = await this.ctx.$axios.$post(route, defaultData);
            return response.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description get list of users who have permission to item
     * @param id id of infopack or process
     * @returns any
     */
    async getPermissions(id: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/permissions`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description publish changes of draft. writing change to public version.
     * @param id id of infopack or process
     * @returns any
     */
    async publishChanges(id: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${id}/publish/changes`;

            const response = await this.ctx.$axios.$post(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description set content for the specific tab of pack
     * @param contentType type of content: image, file, video, link, etc
     * @param packId id of process or infopack
     * @param tabId id of tab
     * @param params content details to upload
     * @returns any
     */
    async uploadContent(
        contentType: string,
        packId: number,
        tabId: number,
        params: object = {}
    ): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${packId}/tabs/${tabId}/content/${contentType}`;
            const infoPackUploadResult: AxiosResult = await this.ctx.$axios.$post(route, params);
            return infoPackUploadResult.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description set content for the specific tab of pack
	 *
     * @param params content details to upload
     * @returns any
     */
    async uploadContentFromGateway(params: object = {}): Promise<any> {
        try {
            const infoPackUploadResult: AxiosResult = await this.ctx.$axios.$post(
                gatewayPath + '/v1/content',
                params
            );
            return infoPackUploadResult.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description delete a content for the specific tab of pack
     * @param contentType type of content: image, file, video, link, etc
     * @param packId id of process or infopack
     * @param tabId id of tab
     * @param contentId id of content to delete
     * @returns any
     */
    async deleteContent(
        contentType: string,
        packId: number,
        tabId: number,
        contentId: number
    ): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${packId}/tabs/${tabId}/content/${contentType}/${contentId}`;
            const infoPackUploadResult: AxiosResult = await this.ctx.$axios.$delete(route);
            return infoPackUploadResult.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description edit a content for the specific tab of pack
     * @param contentType type of content: image, file, video, link, etc
     * @param packId id of process or infopack
     * @param tabId id of tab
     * @param contentId id of content to delete
     * @param params content details to upload
     * @returns any
     */
    async editContent(
        contentType: string,
        packId: number,
        tabId: number,
        contentId: number,
        params: object = {}
    ): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/${packId}/tabs/${tabId}/content/${contentType}/${contentId}`;
            const infoPackUploadResult: AxiosResult = await this.ctx.$axios.$put(route, params);
            return infoPackUploadResult.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description edit a content for the specific tab of pack
     * @param contentType type of content: image, file, video, link, etc
     * @param packId id of process or infopack
     * @param tabId id of tab
     * @param contentId id of content to delete
     * @param params content details to upload
     * @returns any
     */
    async editContentFromGateway(params: object = {}): Promise<any> {
        try {
            const route = `${gatewayPath}/v1/content`;
            const infoPackUploadResult: AxiosResult = await this.ctx.$axios.$put(route, params);
            return infoPackUploadResult.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description fetch all contents of user that he has uploaded
     * @param contentType type of content: 2 for images, 3 for video, etc - search ContentTypeNumeric
     * @param offset number
     * @param limit number
     * @returns any
     */
    async getMyContent(contentType: number, offset: number, limit: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/myContent/${contentType}`;
            const response: AxiosResult = await this.ctx.$axios.$get(route, {
                params: { offset, limit },
            });
            return response as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description delete specific content of user
     * @param contentType type of content: 2 for images, 3 for video, etc - search ContentTypeNumeric
     * @param contentId number
     * @returns any
     */
    async deleteMyContent(contentType: number, contentId: number): Promise<any> {
        try {
            const route = `${servicePath}/management/infopacks/myContent/${contentType}/${contentId}`;
            const response: AxiosResult = await this.ctx.$axios.$delete(route);
            return response as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * Pins an item
     * @param  {number} userId
     * @param  {number} permissionId
     * @returns Promise
     */
    async pin(userId: number, permissionId: number): Promise<any> {
        try {
            const response = await this.ctx.$axios.$post(
                URI({
                    base: ApiConfig.infopack.uri,
                    endpoint: ApiConfig.infopack.pin,
                }),
                {
                    userId,
                    permissionId,
                }
            );

            return response.data;
        } catch (error) {
            throw error.response || error;
        }
    },
};
