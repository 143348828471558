












import { Vue, Component } from 'nuxt-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    components: {},
    computed: {
        ...mapGetters('search', { searchBar: 'searchBar' }),
    },
})
export default class Default extends Vue {
    header: string = '';

    head() {
        return {
            meta: [
                // hid is used as unique identifier. Do not use `vmid` for it as it will not work
                {
                    hid: 'canonical',
                    name: 'canonical',
                    content: `https://infodeniz.com${this.$route.fullPath}`,
                },
            ],
        };
    }

    created() {
        if (this.$route.query.searchMode) {
            const query = Object.assign({}, this.$route.query);
            delete query.searchMode;
            this.$router.replace({
                query,
            });
        }
    }

    get isChatPage() {
        return this.$store.getters.getIsChatPage;
    }
}
