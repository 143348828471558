import { ApiConfig } from '~/config';

const servicePath = '/api/v1/ua';
const gatewayPath = ApiConfig.gateway.v1;
export default {
    async getUserProfile(username: string, callSelf: boolean): Promise<any> {
        try {
            const route = callSelf
                ? `${ApiConfig.gateway.v1}user`
                : `${servicePath}/users/profile/${username}`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            this.ctx.$apiErrorHandler(error);
        }
    },

    async uploadAvatar(formData: FormData, progress: Function): Promise<any> {
        try {
            const uploadAvatar = await this.ctx.$axios.put(
                `${servicePath}/users/avatar`,
                formData,
                {},
                {},
                { onUploadProgress: progress }
            );
            return uploadAvatar?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editUserProfile(params: any, targetUserID?: any): Promise<any> {
        try {
            let route = `${servicePath}/users`;
            if (targetUserID) {
                route += `/${targetUserID}`;
            }
            const response = await this.ctx.$axios.put(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editPersonalInfo(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updatePersonalInfo}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editBusinessBiography(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateBusinessBiography}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editBusinessInfo(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateBusinessInfo}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editBusinessIndustry(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateBusinessIndustry}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editBusinessContact(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateBusinessContact}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editEducations(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateEducation}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editExperiences(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateExperiences}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editDestinations(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateDestinations}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editGeneralCertificates(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateGeneralCertificates}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editLanguageCertificates(params: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateLanguageCertificates}`;
            const response = await this.ctx.$axios.patch(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editContactInfo(data: any): Promise<any> {
        try {
            const route = `${gatewayPath}${ApiConfig.user.updateContact}`;
            const response = await this.ctx.$axios.patch(route, data);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async editBusinessProfile(params: any, targetUserID?: any): Promise<any> {
        try {
            let route = `${servicePath}/users/business`;
            if (targetUserID) {
                route += `/${targetUserID}`;
            }

            const response = await this.ctx.$axios.put(route, params);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async verifyPhone(): Promise<any> {
        try {
            const response = await this.ctx.$axios.post(`${servicePath}/auth/verify/phone`);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async verifyEmail(): Promise<any> {
        try {
            const response = await this.ctx.$axios.post(`${servicePath}/auth/verify/email`);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async uploadResume(formData: FormData) {
        try {
            const response = await this.ctx.$axios.post(
                `${servicePath}/users/resume`,
                formData,
                {},
                {}
            );
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async removeResume() {
        try {
            const response = await this.ctx.$axios.delete(`${servicePath}/users/resume/remove`);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async uploadFile(formData: FormData, progress: Function) {
        try {
            const uploadAvatar = await this.ctx.$axios.put(
                `${servicePath}/users/avatar`,
                formData,
                {},
                {},
                { onUploadProgress: progress }
            );
            return uploadAvatar?.data as any;
        } catch (e) {
            return e.response;
        }
    },

    async getUserProfileInfo(userId?: any): Promise<any> {
        try {
            let route = `${servicePath}/users/self`;
            if (userId) {
                route += `/${userId}`;
            }
            const response = await this.ctx.$axios.get(route);
            return response?.data?.data as any;
        } catch (e) {
            throw e.response;
        }
    },
    async updatePaymentInfo() {
        try {
            const route = `${servicePath}/users/payment`;
            const response = await this.ctx.$axios.put(route);
            return response?.data?.data;
        } catch (e) {
            throw e.response;
        }
    },
};
