export default {
    methods: {
        async mxCopyToClipboard(text) {
            try {
                if (navigator.clipboard?.writeText) {
                    await navigator.clipboard.writeText(text);
                } else if (window?.clipboardData?.setData) {
                    return Promise.resolve(window.clipboardData.setData('Text', text));
                } else {
                    throw new Error('no way to copy?!');
                }
            } catch (error) {
                throw new Error('no way to copy?!');
            }
        },
    },
};
