//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    layout: 'empty',
    props: {
        error: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            pageNotFound: 'صفحه مورد نظر یافت نشد - 404',
            otherError: 'خطای داخلی سرور - 500',
        };
    },
    head() {
        const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
        return {
            title,
        };
    },
    computed: {
        imageHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return '200px';
                case 'sm':
                    return '300px';
                case 'md':
                    return '500px';
                default:
                    return '600px';
            }
        },
    },
};
