const servicePath = '/api/v1';
const gatewayPath = '/gateway/v1';
export default {
    async getGuestHome(): Promise<any> {
        try {
            const route = `${gatewayPath}/home/guest`;

            const response = await this.ctx.$axios.$get(route);

            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async sendFeedback(data: { text: string }): Promise<any> {
        try {
            const route = `${servicePath}/social/subscription/feedback`;

            const response = await this.ctx.$axios.$post(route, data);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    async getUserHome(): Promise<any> {
        try {
            const route = `${servicePath}/ip/infopacks/home`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async getNewUserHome(): Promise<any> {
        try {
            const route = `${gatewayPath}/home/auth`;

            const response = await this.ctx.$axios.$get(route);

            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },

    async getSuggestedAuthContent(limit: number = 3, offset: number = 0): Promise<any> {
        try {
            const route = `${gatewayPath}/home/auth/suggested`;

            const response = await this.ctx.$axios.$get(route, {
                params: {
                    limit,
                    offset,
                },
            });

            return (response?.data || response) as any;
        } catch (e) {
            throw e.response;
        }
    },

    async getSuggestedContent(limit: number = 3, offset: number = 0): Promise<any> {
        try {
            const route = `${gatewayPath}/home/suggested`;

            const response = await this.ctx.$axios.$get(route, {
                params: {
                    limit,
                    offset,
                },
            });
            return (response?.data || response) as any;
        } catch (e) {
            throw e.response;
        }
    },
};
