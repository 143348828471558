const servicePath = '/api/v1/ua';
const IR_CURRENCY = 4;
const ZARINPAL_GATEWAY = 1;

interface Action {
    target: number;
    id: number;
    refId: number;
    silent?: boolean;
    meta: { token: string; type: number };
}

export default {
    async pay(amount: string, action?: Action): Promise<any> {
        try {
            const response = await this.ctx.$axios.$post(`${servicePath}/transaction/pay`, {
                amount,
                currency: IR_CURRENCY,
                gateway: ZARINPAL_GATEWAY,
                action,
            });
            return response;
        } catch (error) {
            throw error.response || error;
        }
    },

    async history({ offset = 0, limit = 10 }: { offset: number; limit: number }): Promise<any> {
        try {
            const response = await this.ctx.$axios.$get(
                `${servicePath}/transaction/history/wallet`,
                {
                    params: {
                        offset,
                        limit,
                    },
                }
            );
            return response;
        } catch (error) {
            throw error.response || error;
        }
    },

    async credit(): Promise<any> {
        try {
            const response = await this.ctx.$axios.$get(`${servicePath}/payment/credit`, {
                params: {
                    currency: IR_CURRENCY,
                },
            });
            return response;
        } catch (error) {
            throw error.response || error;
        }
    },

    async finish(orderId: string): Promise<any> {
        try {
            const response = await this.ctx.$axios.$post(
                `${servicePath}/transaction/finish/${orderId}`
            );
            return response.data;
        } catch (error) {
            throw error.response || error;
        }
    },

    async withdraw(amount: number, IBAN?: string): Promise<any> {
        const data: any = {
            amount,
            currency: IR_CURRENCY,
        };
        if (IBAN) {
            data.IBAN = IBAN;
        }

        try {
            const request = await this.ctx.$axios.$post(
                `${servicePath}/transaction/withdraw`,
                data
            );
            const response = request.data;

            return response.data;
        } catch (error) {
            throw error.response || error;
        }
    },
};
