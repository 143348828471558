import io from './socket.io';

export class StartConversationDto {
    infopack: {
        id: number;
        title: string;
    };
    receiverId: number;

    constructor(data: StartConversationDto) {
        this.receiverId = data.receiverId;
        this.infopack = data.infopack;
        return this;
    }
}

export class CreateMessageDto {
    roomId: string; //objectId
    infopack: {
        id: number;
        title: string;
    };
    text: string;

    constructor(data: CreateMessageDto) {
        this.roomId = data.roomId;
        this.infopack = data.infopack;
        this.text = data.text;
        return this;
    }
}

export class MessageIsTypingDto {
    roomId: string; //objectId
    constructor(data: MessageIsTypingDto) {
        this.roomId = data.roomId;
        return this;
    }
}

export class MessageReceivedDto {
    messageId: string;

    constructor(data: MessageReceivedDto) {
        this.messageId = data.messageId;
        return this;
    }
}

export const socketEvent = {
    startConversation: 'startConversation',
    startConversationCb: 'startConversationCb',
    acceptAdviceCb: 'acceptAdviceCb',
    createMessage: 'createMessage',
    createMessageCb: 'newMessage',
    messageIsTyping: 'messageIsTyping',
    messageIsTypingCb: 'messageIsTypingCb',
    messageReceived: 'messageReceived',
    messageReceivedCb: 'messageReceivedCb',
    errorCb: 'error-callback',
    seenMessage: 'seenMessage',
};

export class SocketSdk {
    private static instance: SocketSdk;
    private socket;
    public _authorization;
    public _language;
    private constructor({ authorization, language }: { authorization: string; language: string }) {
        this._authorization = authorization;
        this._language = language;
        this.socket = io('', {
            path: '/api/v1/social/socket',
            reconnectionDelayMax: 10000,
            query: {
                language: language,
                'x-authorization': authorization,
            },
        });
        this.socket.on('connect', function () {});

        this.socket.on('disconnect', function () {});
        return this;
    }

    public static getInstance(authorization: string, language: string): SocketSdk {
        if (!SocketSdk.instance) {
            SocketSdk.instance = new SocketSdk({
                authorization,
                language,
            });
        }

        const { _authorization, _language } = SocketSdk.instance;

        if (_authorization !== authorization || language !== _language) {
            SocketSdk.deleteInstance();
            SocketSdk.instance = new SocketSdk({
                authorization,
                language,
            });
        }

        return SocketSdk.instance;
    }

    public static deleteInstance(): void {
        if (SocketSdk.instance) {
            SocketSdk.instance._authorization = undefined;
            SocketSdk.instance._language = undefined;
            SocketSdk.instance.disconnect();
            SocketSdk.instance = undefined;
        }
    }

    public disconnect(): void {
        this.socket.disconnect();
        this.socket = undefined;
    }

    // Custom Events
    public sendCustomEvent(event: string, data: unknown, cb: (data: unknown) => void) {
        this.socket.emit(event, data, cb);
    }

    public registerCustomEvent(event: string, cb: (data: unknown) => void) {
        this.socket.on(event, cb);
    }

    // End Of Server Events
    // Server Events
    public registerErrorCbEvent(cb: (data: unknown) => void) {
        this.socket.on(socketEvent.errorCb, cb);
        return () => this.socket.off(socketEvent.startConversationCb);
    }

    public sendCreateConversationEvent(data: StartConversationDto, cb: (data: unknown) => void) {
        this.socket.emit(socketEvent.startConversation, data);
    }

    public registerCreateConversationCbEvent(cb: (data: unknown) => void) {
        this.socket.on(socketEvent.startConversationCb, cb);
        return () => this.socket.off(socketEvent.startConversationCb);
    }

    public sendCreateMessageEvent(data: CreateMessageDto, cb: (data: unknown) => void) {
        this.socket.emit(socketEvent.createMessage, data, cb);
    }

    public registerCreateMessageCbEvent(cb: (data: unknown) => void) {
        this.socket.on(socketEvent.createMessageCb, cb);
        return () => this.socket.off(socketEvent.createMessageCb);
    }

    public sendSeenMessage(messageId: number) {
        this.socket.emit(socketEvent.seenMessage, { messageId: messageId });
    }

    public sendMessageIsTypingEvent(data: MessageIsTypingDto, cb: (data: unknown) => void) {
        this.socket.emit(socketEvent.messageIsTyping, data, cb);
    }

    public registerMessageIsTypingCbEvent(cb: (data: unknown) => void) {
        this.socket.on(socketEvent.messageIsTypingCb, cb);
        return () => this.socket.off(socketEvent.messageIsTypingCb);
    }

    public sendMessageReceivedEvent(data: MessageReceivedDto, cb: (data: unknown) => void) {
        this.socket.emit(socketEvent.messageReceived, data, cb);
    }

    public registerMessageReceivedCbEvent(cb: (data: unknown) => void) {
        this.socket.on(socketEvent.messageReceivedCb, cb);
        return () => this.socket.off(socketEvent.messageReceivedCb);
    }

    public registerAcceptAdviceCbEvent(cb: (data: unknown) => void) {
        this.socket.on(socketEvent.acceptAdviceCb, cb);
        return () => this.socket?.off(socketEvent.createMessageCb);
    }

    // End Of Server Events
}
