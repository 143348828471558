export const Avatar = () => import('../../components/avatar/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const AvatarNavigation = () => import('../../components/avatar/Navigation.vue' /* webpackChunkName: "components/avatar-navigation" */).then(c => wrapFunctional(c.default || c))
export const AvatarGroup = () => import('../../components/avatar/group.vue' /* webpackChunkName: "components/avatar-group" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterMobile = () => import('../../components/footer/mobile.vue' /* webpackChunkName: "components/footer-mobile" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderIncreaseWallet = () => import('../../components/header/IncreaseWallet.vue' /* webpackChunkName: "components/header-increase-wallet" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobile = () => import('../../components/header/Mobile.vue' /* webpackChunkName: "components/header-mobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearch = () => import('../../components/header/Search.vue' /* webpackChunkName: "components/header-search" */).then(c => wrapFunctional(c.default || c))
export const HomeFaq = () => import('../../components/home/faq.vue' /* webpackChunkName: "components/home-faq" */).then(c => wrapFunctional(c.default || c))
export const HomeFeedbackForm = () => import('../../components/home/feedback-form.vue' /* webpackChunkName: "components/home-feedback-form" */).then(c => wrapFunctional(c.default || c))
export const LoginEmailOrPhone = () => import('../../components/login/EmailOrPhone.vue' /* webpackChunkName: "components/login-email-or-phone" */).then(c => wrapFunctional(c.default || c))
export const LoginForgot = () => import('../../components/login/Forgot.vue' /* webpackChunkName: "components/login-forgot" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/login/Login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const LoginPassword = () => import('../../components/login/Password.vue' /* webpackChunkName: "components/login-password" */).then(c => wrapFunctional(c.default || c))
export const LoginResetPassword = () => import('../../components/login/ResetPassword.vue' /* webpackChunkName: "components/login-reset-password" */).then(c => wrapFunctional(c.default || c))
export const LoginCountryCode = () => import('../../components/login/countryCode.ts' /* webpackChunkName: "components/login-country-code" */).then(c => wrapFunctional(c.default || c))
export const ManagementInfopackContent = () => import('../../components/management/InfopackContent.vue' /* webpackChunkName: "components/management-infopack-content" */).then(c => wrapFunctional(c.default || c))
export const ManagementInfopackSidebar = () => import('../../components/management/InfopackSidebar.vue' /* webpackChunkName: "components/management-infopack-sidebar" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/notification/index.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const NotificationMobile = () => import('../../components/notification/mobile.vue' /* webpackChunkName: "components/notification-mobile" */).then(c => wrapFunctional(c.default || c))
export const NotificationScript = () => import('../../components/notification/script.ts' /* webpackChunkName: "components/notification-script" */).then(c => wrapFunctional(c.default || c))
export const People = () => import('../../components/people/People.vue' /* webpackChunkName: "components/people" */).then(c => wrapFunctional(c.default || c))
export const Register = () => import('../../components/register/Register.vue' /* webpackChunkName: "components/register" */).then(c => wrapFunctional(c.default || c))
export const RegisterUserInfo = () => import('../../components/register/UserInfo.vue' /* webpackChunkName: "components/register-user-info" */).then(c => wrapFunctional(c.default || c))
export const RegisterVerifyCode = () => import('../../components/register/VerifyCode.vue' /* webpackChunkName: "components/register-verify-code" */).then(c => wrapFunctional(c.default || c))
export const SearchMobile = () => import('../../components/search/mobile.vue' /* webpackChunkName: "components/search-mobile" */).then(c => wrapFunctional(c.default || c))
export const Sitemap = () => import('../../components/sitemap/sitemap.ts' /* webpackChunkName: "components/sitemap" */).then(c => wrapFunctional(c.default || c))
export const TransactionsList = () => import('../../components/transactions-list/index.vue' /* webpackChunkName: "components/transactions-list" */).then(c => wrapFunctional(c.default || c))
export const TransactionsListMobile = () => import('../../components/transactions-list/mobile.vue' /* webpackChunkName: "components/transactions-list-mobile" */).then(c => wrapFunctional(c.default || c))
export const TransactionsListScripts = () => import('../../components/transactions-list/scripts.ts' /* webpackChunkName: "components/transactions-list-scripts" */).then(c => wrapFunctional(c.default || c))
export const UserProfileDesktop = () => import('../../components/user-profile/desktop.vue' /* webpackChunkName: "components/user-profile-desktop" */).then(c => wrapFunctional(c.default || c))
export const UserProfileMobile = () => import('../../components/user-profile/mobile.vue' /* webpackChunkName: "components/user-profile-mobile" */).then(c => wrapFunctional(c.default || c))
export const UsersListModal = () => import('../../components/usersList/Modal.vue' /* webpackChunkName: "components/users-list-modal" */).then(c => wrapFunctional(c.default || c))
export const Wallet = () => import('../../components/wallet/index.vue' /* webpackChunkName: "components/wallet" */).then(c => wrapFunctional(c.default || c))
export const WalletMobile = () => import('../../components/wallet/mobile.vue' /* webpackChunkName: "components/wallet-mobile" */).then(c => wrapFunctional(c.default || c))
export const WalletScripts = () => import('../../components/wallet/scripts.ts' /* webpackChunkName: "components/wallet-scripts" */).then(c => wrapFunctional(c.default || c))
export const AccountSidebar = () => import('../../components/account/sidebar/index.vue' /* webpackChunkName: "components/account-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CarouselBottomDescDelimiters = () => import('../../components/carousel/bottom-desc-delimiters/index.vue' /* webpackChunkName: "components/carousel-bottom-desc-delimiters" */).then(c => wrapFunctional(c.default || c))
export const CarouselBottomDescDelimitersMobile = () => import('../../components/carousel/bottom-desc-delimiters/mobile.vue' /* webpackChunkName: "components/carousel-bottom-desc-delimiters-mobile" */).then(c => wrapFunctional(c.default || c))
export const CarouselCard = () => import('../../components/carousel/card/index.vue' /* webpackChunkName: "components/carousel-card" */).then(c => wrapFunctional(c.default || c))
export const CarouselMain = () => import('../../components/carousel/main/index.vue' /* webpackChunkName: "components/carousel-main" */).then(c => wrapFunctional(c.default || c))
export const CarouselSideSimpleDelimiters = () => import('../../components/carousel/side-simple-delimiters/index.vue' /* webpackChunkName: "components/carousel-side-simple-delimiters" */).then(c => wrapFunctional(c.default || c))
export const CarouselSideSimpleDelimitersMobile = () => import('../../components/carousel/side-simple-delimiters/mobile.vue' /* webpackChunkName: "components/carousel-side-simple-delimiters-mobile" */).then(c => wrapFunctional(c.default || c))
export const CarouselTopTitleDelimiters = () => import('../../components/carousel/top-title-delimiters/index.vue' /* webpackChunkName: "components/carousel-top-title-delimiters" */).then(c => wrapFunctional(c.default || c))
export const CarouselTopTitleDelimitersMobile = () => import('../../components/carousel/top-title-delimiters/mobile.vue' /* webpackChunkName: "components/carousel-top-title-delimiters-mobile" */).then(c => wrapFunctional(c.default || c))
export const ChatContainer = () => import('../../components/chat/chatContainer/ChatContainer.vue' /* webpackChunkName: "components/chat-container" */).then(c => wrapFunctional(c.default || c))
export const ChatContent = () => import('../../components/chat/chatContent/ChatContent.vue' /* webpackChunkName: "components/chat-content" */).then(c => wrapFunctional(c.default || c))
export const ChatMessagesList = () => import('../../components/chat/chatContent/ChatMessagesList.vue' /* webpackChunkName: "components/chat-messages-list" */).then(c => wrapFunctional(c.default || c))
export const ChatChatContentObserver = () => import('../../components/chat/chatContent/Observer.vue' /* webpackChunkName: "components/chat-chat-content-observer" */).then(c => wrapFunctional(c.default || c))
export const ChatChatContentRecording = () => import('../../components/chat/chatContent/Recording.vue' /* webpackChunkName: "components/chat-chat-content-recording" */).then(c => wrapFunctional(c.default || c))
export const ChatChatContentVoicePreview = () => import('../../components/chat/chatContent/VoicePreview.vue' /* webpackChunkName: "components/chat-chat-content-voice-preview" */).then(c => wrapFunctional(c.default || c))
export const ChatSidebar = () => import('../../components/chat/chatSidebar/ChatSidebar.vue' /* webpackChunkName: "components/chat-sidebar" */).then(c => wrapFunctional(c.default || c))
export const HeaderGuestMobile = () => import('../../components/header/guest/mobile.vue' /* webpackChunkName: "components/header-guest-mobile" */).then(c => wrapFunctional(c.default || c))
export const HeaderUserMobile = () => import('../../components/header/user/mobile.vue' /* webpackChunkName: "components/header-user-mobile" */).then(c => wrapFunctional(c.default || c))
export const HomeGuestDesktop = () => import('../../components/home/guest/desktop.vue' /* webpackChunkName: "components/home-guest-desktop" */).then(c => wrapFunctional(c.default || c))
export const HomeGuestMobile = () => import('../../components/home/guest/mobile.vue' /* webpackChunkName: "components/home-guest-mobile" */).then(c => wrapFunctional(c.default || c))
export const HomeGuestScripts = () => import('../../components/home/guest/scripts.ts' /* webpackChunkName: "components/home-guest-scripts" */).then(c => wrapFunctional(c.default || c))
export const HomeUserDesktop = () => import('../../components/home/user/desktop.vue' /* webpackChunkName: "components/home-user-desktop" */).then(c => wrapFunctional(c.default || c))
export const HomeUserMobile = () => import('../../components/home/user/mobile.vue' /* webpackChunkName: "components/home-user-mobile" */).then(c => wrapFunctional(c.default || c))
export const HomeUserScripts = () => import('../../components/home/user/scripts.js' /* webpackChunkName: "components/home-user-scripts" */).then(c => wrapFunctional(c.default || c))
export const IAddAttentionBtn = () => import('../../components/i/add-attention-btn/index.vue' /* webpackChunkName: "components/i-add-attention-btn" */).then(c => wrapFunctional(c.default || c))
export const IAuthor = () => import('../../components/i/author/index.vue' /* webpackChunkName: "components/i-author" */).then(c => wrapFunctional(c.default || c))
export const IBanner = () => import('../../components/i/banner/index.vue' /* webpackChunkName: "components/i-banner" */).then(c => wrapFunctional(c.default || c))
export const IBottomDrawer = () => import('../../components/i/bottom-drawer/index.vue' /* webpackChunkName: "components/i-bottom-drawer" */).then(c => wrapFunctional(c.default || c))
export const IBottomNavigation = () => import('../../components/i/bottom-navigation/index.vue' /* webpackChunkName: "components/i-bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const IBreadcrumb = () => import('../../components/i/breadcrumb/index.vue' /* webpackChunkName: "components/i-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const IContainer = () => import('../../components/i/container/index.vue' /* webpackChunkName: "components/i-container" */).then(c => wrapFunctional(c.default || c))
export const IContentView = () => import('../../components/i/content-view/index.vue' /* webpackChunkName: "components/i-content-view" */).then(c => wrapFunctional(c.default || c))
export const ICountryCodes = () => import('../../components/i/country-codes/index.vue' /* webpackChunkName: "components/i-country-codes" */).then(c => wrapFunctional(c.default || c))
export const ICreateContentMenu = () => import('../../components/i/create-content-menu/index.vue' /* webpackChunkName: "components/i-create-content-menu" */).then(c => wrapFunctional(c.default || c))
export const IDatePicker = () => import('../../components/i/date-picker/index.vue' /* webpackChunkName: "components/i-date-picker" */).then(c => wrapFunctional(c.default || c))
export const IEmbed = () => import('../../components/i/embed/index.vue' /* webpackChunkName: "components/i-embed" */).then(c => wrapFunctional(c.default || c))
export const IFileDetails = () => import('../../components/i/file-details/index.vue' /* webpackChunkName: "components/i-file-details" */).then(c => wrapFunctional(c.default || c))
export const IFilesList = () => import('../../components/i/files-list/index.vue' /* webpackChunkName: "components/i-files-list" */).then(c => wrapFunctional(c.default || c))
export const IImagesList = () => import('../../components/i/images-list/index.vue' /* webpackChunkName: "components/i-images-list" */).then(c => wrapFunctional(c.default || c))
export const ILightBox = () => import('../../components/i/light-box/index.vue' /* webpackChunkName: "components/i-light-box" */).then(c => wrapFunctional(c.default || c))
export const ILinkDetails = () => import('../../components/i/link-details/index.vue' /* webpackChunkName: "components/i-link-details" */).then(c => wrapFunctional(c.default || c))
export const ILoading = () => import('../../components/i/loading/index.vue' /* webpackChunkName: "components/i-loading" */).then(c => wrapFunctional(c.default || c))
export const ILoadingOverlay = () => import('../../components/i/loading/overlay.vue' /* webpackChunkName: "components/i-loading-overlay" */).then(c => wrapFunctional(c.default || c))
export const ILogo = () => import('../../components/i/logo/index.vue' /* webpackChunkName: "components/i-logo" */).then(c => wrapFunctional(c.default || c))
export const ILogoText = () => import('../../components/i/logo/text.vue' /* webpackChunkName: "components/i-logo-text" */).then(c => wrapFunctional(c.default || c))
export const IModaller = () => import('../../components/i/modaller/index.vue' /* webpackChunkName: "components/i-modaller" */).then(c => wrapFunctional(c.default || c))
export const INotFound = () => import('../../components/i/not-found/index.vue' /* webpackChunkName: "components/i-not-found" */).then(c => wrapFunctional(c.default || c))
export const IProgressCircular = () => import('../../components/i/progress/circular.vue' /* webpackChunkName: "components/i-progress-circular" */).then(c => wrapFunctional(c.default || c))
export const IScrollableNavbar = () => import('../../components/i/scrollable-navbar/index.vue' /* webpackChunkName: "components/i-scrollable-navbar" */).then(c => wrapFunctional(c.default || c))
export const ISnackBar = () => import('../../components/i/snack-bar/index.vue' /* webpackChunkName: "components/i-snack-bar" */).then(c => wrapFunctional(c.default || c))
export const ISnackBarLarge = () => import('../../components/i/snack-bar/large.vue' /* webpackChunkName: "components/i-snack-bar-large" */).then(c => wrapFunctional(c.default || c))
export const ITextClamperCharacters = () => import('../../components/i/text-clamper/characters.vue' /* webpackChunkName: "components/i-text-clamper-characters" */).then(c => wrapFunctional(c.default || c))
export const ITextClamper = () => import('../../components/i/text-clamper/index.vue' /* webpackChunkName: "components/i-text-clamper" */).then(c => wrapFunctional(c.default || c))
export const ITimerButton = () => import('../../components/i/timer-button/index.vue' /* webpackChunkName: "components/i-timer-button" */).then(c => wrapFunctional(c.default || c))
export const ITooltippedText = () => import('../../components/i/tooltipped-text/index.vue' /* webpackChunkName: "components/i-tooltipped-text" */).then(c => wrapFunctional(c.default || c))
export const IUndoBtn = () => import('../../components/i/undo-btn/index.vue' /* webpackChunkName: "components/i-undo-btn" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardCarousel = () => import('../../components/infopack/card/carousel.vue' /* webpackChunkName: "components/infopack-card-carousel" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardManage = () => import('../../components/infopack/card/manage.vue' /* webpackChunkName: "components/infopack-card-manage" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardMobile = () => import('../../components/infopack/card/mobile.vue' /* webpackChunkName: "components/infopack-card-mobile" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardSearch = () => import('../../components/infopack/card/search.vue' /* webpackChunkName: "components/infopack-card-search" */).then(c => wrapFunctional(c.default || c))
export const InfopackChapters = () => import('../../components/infopack/chapters/index.vue' /* webpackChunkName: "components/infopack-chapters" */).then(c => wrapFunctional(c.default || c))
export const InfopackContentDesktop = () => import('../../components/infopack/content/desktop.vue' /* webpackChunkName: "components/infopack-content-desktop" */).then(c => wrapFunctional(c.default || c))
export const InfopackContentMobile = () => import('../../components/infopack/content/mobile.vue' /* webpackChunkName: "components/infopack-content-mobile" */).then(c => wrapFunctional(c.default || c))
export const InfopackContentScripts = () => import('../../components/infopack/content/scripts.js' /* webpackChunkName: "components/infopack-content-scripts" */).then(c => wrapFunctional(c.default || c))
export const InfopackListEmpty = () => import('../../components/infopack/list/empty.vue' /* webpackChunkName: "components/infopack-list-empty" */).then(c => wrapFunctional(c.default || c))
export const InfopackListItem = () => import('../../components/infopack/list/item.vue' /* webpackChunkName: "components/infopack-list-item" */).then(c => wrapFunctional(c.default || c))
export const InfopackList = () => import('../../components/infopack/list/list.vue' /* webpackChunkName: "components/infopack-list" */).then(c => wrapFunctional(c.default || c))
export const InfopackSidebar = () => import('../../components/infopack/sidebar/index.vue' /* webpackChunkName: "components/infopack-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ManagementContentTypes = () => import('../../components/management/content-types/index.vue' /* webpackChunkName: "components/management-content-types" */).then(c => wrapFunctional(c.default || c))
export const ManagementDetailsInfopack = () => import('../../components/management/details/infopack.vue' /* webpackChunkName: "components/management-details-infopack" */).then(c => wrapFunctional(c.default || c))
export const ManagementDetailsProcess = () => import('../../components/management/details/process.vue' /* webpackChunkName: "components/management-details-process" */).then(c => wrapFunctional(c.default || c))
export const ManagementSidebarItems = () => import('../../components/management/sidebar/items.vue' /* webpackChunkName: "components/management-sidebar-items" */).then(c => wrapFunctional(c.default || c))
export const ManagementToolbar = () => import('../../components/management/toolbar/index.vue' /* webpackChunkName: "components/management-toolbar" */).then(c => wrapFunctional(c.default || c))
export const ModalAvatarSelector = () => import('../../components/modal/avatar-selector/index.vue' /* webpackChunkName: "components/modal-avatar-selector" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessAbout = () => import('../../components/modal/business-about/index.vue' /* webpackChunkName: "components/modal-business-about" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessActivityField = () => import('../../components/modal/business-activity-field/index.vue' /* webpackChunkName: "components/modal-business-activity-field" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessAdditionalInfo = () => import('../../components/modal/business-additional-info/index.vue' /* webpackChunkName: "components/modal-business-additional-info" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessInformation = () => import('../../components/modal/business-information/index.vue' /* webpackChunkName: "components/modal-business-information" */).then(c => wrapFunctional(c.default || c))
export const ModalCertification = () => import('../../components/modal/certification/index.vue' /* webpackChunkName: "components/modal-certification" */).then(c => wrapFunctional(c.default || c))
export const ModalCopyChapter = () => import('../../components/modal/copy-chapter/index.vue' /* webpackChunkName: "components/modal-copy-chapter" */).then(c => wrapFunctional(c.default || c))
export const ModalCopyModal = () => import('../../components/modal/copy-modal/index.vue' /* webpackChunkName: "components/modal-copy-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalCopyModalNew = () => import('../../components/modal/copy-modal/new.vue' /* webpackChunkName: "components/modal-copy-modal-new" */).then(c => wrapFunctional(c.default || c))
export const ModalEducationalInformation = () => import('../../components/modal/educational-information/index.vue' /* webpackChunkName: "components/modal-educational-information" */).then(c => wrapFunctional(c.default || c))
export const ModalEmailVerification = () => import('../../components/modal/email-verification/index.vue' /* webpackChunkName: "components/modal-email-verification" */).then(c => wrapFunctional(c.default || c))
export const ModalHealthStatus = () => import('../../components/modal/health-status/index.vue' /* webpackChunkName: "components/modal-health-status" */).then(c => wrapFunctional(c.default || c))
export const ModalImagePicker = () => import('../../components/modal/image-picker/index.vue' /* webpackChunkName: "components/modal-image-picker" */).then(c => wrapFunctional(c.default || c))
export const ModalImmigrationInformation = () => import('../../components/modal/immigration-information/index.vue' /* webpackChunkName: "components/modal-immigration-information" */).then(c => wrapFunctional(c.default || c))
export const ModalIncreaseWallet = () => import('../../components/modal/increase-wallet/index.vue' /* webpackChunkName: "components/modal-increase-wallet" */).then(c => wrapFunctional(c.default || c))
export const ModalLanguageLicense = () => import('../../components/modal/language-license/index.vue' /* webpackChunkName: "components/modal-language-license" */).then(c => wrapFunctional(c.default || c))
export const ModalLoadingModal = () => import('../../components/modal/loading-modal/index.vue' /* webpackChunkName: "components/modal-loading-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalLoginRegister = () => import('../../components/modal/login-register/index.vue' /* webpackChunkName: "components/modal-login-register" */).then(c => wrapFunctional(c.default || c))
export const ModalMembers = () => import('../../components/modal/members/index.vue' /* webpackChunkName: "components/modal-members" */).then(c => wrapFunctional(c.default || c))
export const ModalMobileVerification = () => import('../../components/modal/mobile-verification/index.vue' /* webpackChunkName: "components/modal-mobile-verification" */).then(c => wrapFunctional(c.default || c))
export const ModalPaymentConfirm = () => import('../../components/modal/payment-confirm/index.vue' /* webpackChunkName: "components/modal-payment-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalPersonalInformation = () => import('../../components/modal/personal-information/index.vue' /* webpackChunkName: "components/modal-personal-information" */).then(c => wrapFunctional(c.default || c))
export const ModalProfileSections = () => import('../../components/modal/profile-sections/index.vue' /* webpackChunkName: "components/modal-profile-sections" */).then(c => wrapFunctional(c.default || c))
export const ModalPublishSetting = () => import('../../components/modal/publish-setting/index.vue' /* webpackChunkName: "components/modal-publish-setting" */).then(c => wrapFunctional(c.default || c))
export const ModalShare = () => import('../../components/modal/share/index.vue' /* webpackChunkName: "components/modal-share" */).then(c => wrapFunctional(c.default || c))
export const ModalSimpleConfirm = () => import('../../components/modal/simple-confirm/index.vue' /* webpackChunkName: "components/modal-simple-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalSuccessPublish = () => import('../../components/modal/success-publish/index.vue' /* webpackChunkName: "components/modal-success-publish" */).then(c => wrapFunctional(c.default || c))
export const ModalUserAccesses = () => import('../../components/modal/user-accesses/index.vue' /* webpackChunkName: "components/modal-user-accesses" */).then(c => wrapFunctional(c.default || c))
export const ModalUserExperience = () => import('../../components/modal/user-experience/index.vue' /* webpackChunkName: "components/modal-user-experience" */).then(c => wrapFunctional(c.default || c))
export const ModalUserSocial = () => import('../../components/modal/user-social/index.vue' /* webpackChunkName: "components/modal-user-social" */).then(c => wrapFunctional(c.default || c))
export const ModalUsersAssign = () => import('../../components/modal/users-assign/index.vue' /* webpackChunkName: "components/modal-users-assign" */).then(c => wrapFunctional(c.default || c))
export const ModalWithdraw = () => import('../../components/modal/withdraw/index.vue' /* webpackChunkName: "components/modal-withdraw" */).then(c => wrapFunctional(c.default || c))
export const NotificationType = () => import('../../components/notification/type/index.vue' /* webpackChunkName: "components/notification-type" */).then(c => wrapFunctional(c.default || c))
export const NotificationTypeMobile = () => import('../../components/notification/type/mobile.vue' /* webpackChunkName: "components/notification-type-mobile" */).then(c => wrapFunctional(c.default || c))
export const PAddTab = () => import('../../components/p/add-tab/index.vue' /* webpackChunkName: "components/p-add-tab" */).then(c => wrapFunctional(c.default || c))
export const PFullCard = () => import('../../components/p/full-card/index.vue' /* webpackChunkName: "components/p-full-card" */).then(c => wrapFunctional(c.default || c))
export const PKeywords = () => import('../../components/p/keywords/index.vue' /* webpackChunkName: "components/p-keywords" */).then(c => wrapFunctional(c.default || c))
export const PMember = () => import('../../components/p/member/index.vue' /* webpackChunkName: "components/p-member" */).then(c => wrapFunctional(c.default || c))
export const PMembersList = () => import('../../components/p/members/list.vue' /* webpackChunkName: "components/p-members-list" */).then(c => wrapFunctional(c.default || c))
export const PMembersBottomDrawer = () => import('../../components/p/members-bottom-drawer/index.vue' /* webpackChunkName: "components/p-members-bottom-drawer" */).then(c => wrapFunctional(c.default || c))
export const PMobileBottomMenu = () => import('../../components/p/mobile-bottom-menu/index.vue' /* webpackChunkName: "components/p-mobile-bottom-menu" */).then(c => wrapFunctional(c.default || c))
export const PPackFilters = () => import('../../components/p/pack-filters/index.vue' /* webpackChunkName: "components/p-pack-filters" */).then(c => wrapFunctional(c.default || c))
export const PPackStatus = () => import('../../components/p/pack-status/index.vue' /* webpackChunkName: "components/p-pack-status" */).then(c => wrapFunctional(c.default || c))
export const PPackTabs = () => import('../../components/p/pack-tabs/index.vue' /* webpackChunkName: "components/p-pack-tabs" */).then(c => wrapFunctional(c.default || c))
export const PPackTabsMobile = () => import('../../components/p/pack-tabs/mobile.vue' /* webpackChunkName: "components/p-pack-tabs-mobile" */).then(c => wrapFunctional(c.default || c))
export const PPublicCard = () => import('../../components/p/public-card/index.vue' /* webpackChunkName: "components/p-public-card" */).then(c => wrapFunctional(c.default || c))
export const PSidebarActions = () => import('../../components/p/sidebar-actions/index.vue' /* webpackChunkName: "components/p-sidebar-actions" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardCarousel = () => import('../../components/process/card/carousel.vue' /* webpackChunkName: "components/process-card-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardManage = () => import('../../components/process/card/manage.vue' /* webpackChunkName: "components/process-card-manage" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardParticipated = () => import('../../components/process/card/participated.vue' /* webpackChunkName: "components/process-card-participated" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardRegistered = () => import('../../components/process/card/registered.vue' /* webpackChunkName: "components/process-card-registered" */).then(c => wrapFunctional(c.default || c))
export const ProcessContentDesktop = () => import('../../components/process/content/desktop.vue' /* webpackChunkName: "components/process-content-desktop" */).then(c => wrapFunctional(c.default || c))
export const ProcessContentMobile = () => import('../../components/process/content/mobile.vue' /* webpackChunkName: "components/process-content-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProcessContentScripts = () => import('../../components/process/content/scripts.js' /* webpackChunkName: "components/process-content-scripts" */).then(c => wrapFunctional(c.default || c))
export const ProcessContentMenu = () => import('../../components/process/content-menu/index.vue' /* webpackChunkName: "components/process-content-menu" */).then(c => wrapFunctional(c.default || c))
export const ProcessListEmpty = () => import('../../components/process/list/empty.vue' /* webpackChunkName: "components/process-list-empty" */).then(c => wrapFunctional(c.default || c))
export const ProcessListItem = () => import('../../components/process/list/item.vue' /* webpackChunkName: "components/process-list-item" */).then(c => wrapFunctional(c.default || c))
export const ProcessList = () => import('../../components/process/list/list.vue' /* webpackChunkName: "components/process-list" */).then(c => wrapFunctional(c.default || c))
export const ProcessSidebar = () => import('../../components/process/sidebar/index.vue' /* webpackChunkName: "components/process-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ProcessStepsList = () => import('../../components/process/steps-list/index.vue' /* webpackChunkName: "components/process-steps-list" */).then(c => wrapFunctional(c.default || c))
export const ProcessTaskEdit = () => import('../../components/process/task-edit/task-edit.vue' /* webpackChunkName: "components/process-task-edit" */).then(c => wrapFunctional(c.default || c))
export const ProcessTaskUsers = () => import('../../components/process/task-users/task-users.vue' /* webpackChunkName: "components/process-task-users" */).then(c => wrapFunctional(c.default || c))
export const ProfileAvatar = () => import('../../components/profile/avatar/index.vue' /* webpackChunkName: "components/profile-avatar" */).then(c => wrapFunctional(c.default || c))
export const ProfileAvatarMenu = () => import('../../components/profile/avatar-menu/index.vue' /* webpackChunkName: "components/profile-avatar-menu" */).then(c => wrapFunctional(c.default || c))
export const ProfileStatus = () => import('../../components/profile/status/index.vue' /* webpackChunkName: "components/profile-status" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardAboutBusiness = () => import('../../components/user-profile/card/about-business.vue' /* webpackChunkName: "components/user-profile-card-about-business" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardActivityField = () => import('../../components/user-profile/card/activity-field.vue' /* webpackChunkName: "components/user-profile-card-activity-field" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardBusinessAdditionalInfo = () => import('../../components/user-profile/card/business-additional-info.vue' /* webpackChunkName: "components/user-profile-card-business-additional-info" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardBusinessInfo = () => import('../../components/user-profile/card/business-info.vue' /* webpackChunkName: "components/user-profile-card-business-info" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardEmailAddress = () => import('../../components/user-profile/card/email-address.vue' /* webpackChunkName: "components/user-profile-card-email-address" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardImmigrationInfo = () => import('../../components/user-profile/card/immigration-info.vue' /* webpackChunkName: "components/user-profile-card-immigration-info" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardInfo = () => import('../../components/user-profile/card/info.vue' /* webpackChunkName: "components/user-profile-card-info" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardPhoneNumber = () => import('../../components/user-profile/card/phone-number.vue' /* webpackChunkName: "components/user-profile-card-phone-number" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardResume = () => import('../../components/user-profile/card/resume.vue' /* webpackChunkName: "components/user-profile-card-resume" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardSimple = () => import('../../components/user-profile/card/simple.vue' /* webpackChunkName: "components/user-profile-card-simple" */).then(c => wrapFunctional(c.default || c))
export const UserProfileCardSocial = () => import('../../components/user-profile/card/social.vue' /* webpackChunkName: "components/user-profile-card-social" */).then(c => wrapFunctional(c.default || c))
export const UserProfileSidebar = () => import('../../components/user-profile/sidebar/index.vue' /* webpackChunkName: "components/user-profile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const UserProfileTabsAbout = () => import('../../components/user-profile/tabs/about.vue' /* webpackChunkName: "components/user-profile-tabs-about" */).then(c => wrapFunctional(c.default || c))
export const UserProfileTabsInfopacks = () => import('../../components/user-profile/tabs/infopacks.vue' /* webpackChunkName: "components/user-profile-tabs-infopacks" */).then(c => wrapFunctional(c.default || c))
export const UserProfileTabsProcesses = () => import('../../components/user-profile/tabs/processes.vue' /* webpackChunkName: "components/user-profile-tabs-processes" */).then(c => wrapFunctional(c.default || c))
export const AccountManagementWithdraw = () => import('../../components/account/management/withdraw/index.vue' /* webpackChunkName: "components/account-management-withdraw" */).then(c => wrapFunctional(c.default || c))
export const AccountManagementWithdrawMobile = () => import('../../components/account/management/withdraw/mobile.vue' /* webpackChunkName: "components/account-management-withdraw-mobile" */).then(c => wrapFunctional(c.default || c))
export const IAudioDetails = () => import('../../components/i/audio/details/index.vue' /* webpackChunkName: "components/i-audio-details" */).then(c => wrapFunctional(c.default || c))
export const IAudioDetailsSmall = () => import('../../components/i/audio/details/small.vue' /* webpackChunkName: "components/i-audio-details-small" */).then(c => wrapFunctional(c.default || c))
export const IAudioPreview = () => import('../../components/i/audio/preview/index.vue' /* webpackChunkName: "components/i-audio-preview" */).then(c => wrapFunctional(c.default || c))
export const IAudioPreviewSmall = () => import('../../components/i/audio/preview/small.vue' /* webpackChunkName: "components/i-audio-preview-small" */).then(c => wrapFunctional(c.default || c))
export const IEditArea = () => import('../../components/i/edit/area/index.vue' /* webpackChunkName: "components/i-edit-area" */).then(c => wrapFunctional(c.default || c))
export const IEditButton = () => import('../../components/i/edit/button/index.vue' /* webpackChunkName: "components/i-edit-button" */).then(c => wrapFunctional(c.default || c))
export const IEditChip = () => import('../../components/i/edit/chip/index.vue' /* webpackChunkName: "components/i-edit-chip" */).then(c => wrapFunctional(c.default || c))
export const IEditMoney = () => import('../../components/i/edit/money/index.vue' /* webpackChunkName: "components/i-edit-money" */).then(c => wrapFunctional(c.default || c))
export const IEditOtp = () => import('../../components/i/edit/otp/index.vue' /* webpackChunkName: "components/i-edit-otp" */).then(c => wrapFunctional(c.default || c))
export const IEditSearch = () => import('../../components/i/edit/search/index.vue' /* webpackChunkName: "components/i-edit-search" */).then(c => wrapFunctional(c.default || c))
export const IEditText = () => import('../../components/i/edit/text/index.vue' /* webpackChunkName: "components/i-edit-text" */).then(c => wrapFunctional(c.default || c))
export const IEditTextSwitch = () => import('../../components/i/edit/text-switch/index.vue' /* webpackChunkName: "components/i-edit-text-switch" */).then(c => wrapFunctional(c.default || c))
export const IImagePreview = () => import('../../components/i/image/preview/index.vue' /* webpackChunkName: "components/i-image-preview" */).then(c => wrapFunctional(c.default || c))
export const IImageSkeleton = () => import('../../components/i/image/skeleton/index.vue' /* webpackChunkName: "components/i-image-skeleton" */).then(c => wrapFunctional(c.default || c))
export const IImageUploader = () => import('../../components/i/image/uploader/index.vue' /* webpackChunkName: "components/i-image-uploader" */).then(c => wrapFunctional(c.default || c))
export const IVideoCover = () => import('../../components/i/video/cover/index.vue' /* webpackChunkName: "components/i-video-cover" */).then(c => wrapFunctional(c.default || c))
export const IVideoDetails = () => import('../../components/i/video/details/index.vue' /* webpackChunkName: "components/i-video-details" */).then(c => wrapFunctional(c.default || c))
export const IVideoDetailsSmall = () => import('../../components/i/video/details/small.vue' /* webpackChunkName: "components/i-video-details-small" */).then(c => wrapFunctional(c.default || c))
export const IVideoPreview = () => import('../../components/i/video/preview/index.vue' /* webpackChunkName: "components/i-video-preview" */).then(c => wrapFunctional(c.default || c))
export const IVideoPreviewSmall = () => import('../../components/i/video/preview/small.vue' /* webpackChunkName: "components/i-video-preview-small" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardFullHorizontal = () => import('../../components/infopack/card/full/horizontal.vue' /* webpackChunkName: "components/infopack-card-full-horizontal" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardFullScripts = () => import('../../components/infopack/card/full/scripts.ts' /* webpackChunkName: "components/infopack-card-full-scripts" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardFullVertical = () => import('../../components/infopack/card/full/vertical.vue' /* webpackChunkName: "components/infopack-card-full-vertical" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardVisitorChaptersList = () => import('../../components/infopack/card/visitor/chapters-list.vue' /* webpackChunkName: "components/infopack-card-visitor-chapters-list" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardVisitorDesktop = () => import('../../components/infopack/card/visitor/desktop.vue' /* webpackChunkName: "components/infopack-card-visitor-desktop" */).then(c => wrapFunctional(c.default || c))
export const InfopackCardVisitorMobile = () => import('../../components/infopack/card/visitor/mobile.vue' /* webpackChunkName: "components/infopack-card-visitor-mobile" */).then(c => wrapFunctional(c.default || c))
export const InfopackTabsAbout = () => import('../../components/infopack/tabs/about/index.vue' /* webpackChunkName: "components/infopack-tabs-about" */).then(c => wrapFunctional(c.default || c))
export const InfopackTabsAboutMobile = () => import('../../components/infopack/tabs/about/mobile.vue' /* webpackChunkName: "components/infopack-tabs-about-mobile" */).then(c => wrapFunctional(c.default || c))
export const InfopackTabsContent = () => import('../../components/infopack/tabs/content/index.vue' /* webpackChunkName: "components/infopack-tabs-content" */).then(c => wrapFunctional(c.default || c))
export const ManagementSidebarHeader = () => import('../../components/management/sidebar/header/index.vue' /* webpackChunkName: "components/management-sidebar-header" */).then(c => wrapFunctional(c.default || c))
export const ManagementSidebarSubtab = () => import('../../components/management/sidebar/subtab/index.vue' /* webpackChunkName: "components/management-sidebar-subtab" */).then(c => wrapFunctional(c.default || c))
export const ManagementSidebarSubtabItem = () => import('../../components/management/sidebar/subtab/item.vue' /* webpackChunkName: "components/management-sidebar-subtab-item" */).then(c => wrapFunctional(c.default || c))
export const ManagementSidebarTab = () => import('../../components/management/sidebar/tab/index.vue' /* webpackChunkName: "components/management-sidebar-tab" */).then(c => wrapFunctional(c.default || c))
export const ManagementTabsAudio = () => import('../../components/management/tabs/audio/index.vue' /* webpackChunkName: "components/management-tabs-audio" */).then(c => wrapFunctional(c.default || c))
export const ManagementTabsDescription = () => import('../../components/management/tabs/description/Description.vue' /* webpackChunkName: "components/management-tabs-description" */).then(c => wrapFunctional(c.default || c))
export const ManagementTabsFile = () => import('../../components/management/tabs/file/index.vue' /* webpackChunkName: "components/management-tabs-file" */).then(c => wrapFunctional(c.default || c))
export const ManagementTabsImage = () => import('../../components/management/tabs/image/index.vue' /* webpackChunkName: "components/management-tabs-image" */).then(c => wrapFunctional(c.default || c))
export const ManagementTabsLink = () => import('../../components/management/tabs/link/index.vue' /* webpackChunkName: "components/management-tabs-link" */).then(c => wrapFunctional(c.default || c))
export const ManagementTabsTasksList = () => import('../../components/management/tabs/tasks-list/index.vue' /* webpackChunkName: "components/management-tabs-tasks-list" */).then(c => wrapFunctional(c.default || c))
export const ManagementTabsTasksListItem = () => import('../../components/management/tabs/tasks-list/item.vue' /* webpackChunkName: "components/management-tabs-tasks-list-item" */).then(c => wrapFunctional(c.default || c))
export const ManagementTabsVideo = () => import('../../components/management/tabs/video/index.vue' /* webpackChunkName: "components/management-tabs-video" */).then(c => wrapFunctional(c.default || c))
export const ModalUploadAudio = () => import('../../components/modal/upload/audio/index.vue' /* webpackChunkName: "components/modal-upload-audio" */).then(c => wrapFunctional(c.default || c))
export const ModalUploadFile = () => import('../../components/modal/upload/file/index.vue' /* webpackChunkName: "components/modal-upload-file" */).then(c => wrapFunctional(c.default || c))
export const ModalUploadImage = () => import('../../components/modal/upload/image/index.vue' /* webpackChunkName: "components/modal-upload-image" */).then(c => wrapFunctional(c.default || c))
export const ModalUploadLink = () => import('../../components/modal/upload/link/index.vue' /* webpackChunkName: "components/modal-upload-link" */).then(c => wrapFunctional(c.default || c))
export const ModalUploadVideo = () => import('../../components/modal/upload/video/index.vue' /* webpackChunkName: "components/modal-upload-video" */).then(c => wrapFunctional(c.default || c))
export const ModalUploadVideoLink = () => import('../../components/modal/upload/video/link.vue' /* webpackChunkName: "components/modal-upload-video-link" */).then(c => wrapFunctional(c.default || c))
export const NotificationTypeAssignTask = () => import('../../components/notification/type/assignTask/index.vue' /* webpackChunkName: "components/notification-type-assign-task" */).then(c => wrapFunctional(c.default || c))
export const NotificationTypeChatMessage = () => import('../../components/notification/type/chatMessage/index.vue' /* webpackChunkName: "components/notification-type-chat-message" */).then(c => wrapFunctional(c.default || c))
export const NotificationTypeCopyInfopack = () => import('../../components/notification/type/copyInfopack/index.vue' /* webpackChunkName: "components/notification-type-copy-infopack" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardFullHorizontal = () => import('../../components/process/card/full/horizontal.vue' /* webpackChunkName: "components/process-card-full-horizontal" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardFullScripts = () => import('../../components/process/card/full/scripts.ts' /* webpackChunkName: "components/process-card-full-scripts" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardFullVertical = () => import('../../components/process/card/full/vertical.vue' /* webpackChunkName: "components/process-card-full-vertical" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardVisitorDesktop = () => import('../../components/process/card/visitor/desktop.vue' /* webpackChunkName: "components/process-card-visitor-desktop" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardVisitorLevelsList = () => import('../../components/process/card/visitor/levels-list.vue' /* webpackChunkName: "components/process-card-visitor-levels-list" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardVisitorMobile = () => import('../../components/process/card/visitor/mobile.vue' /* webpackChunkName: "components/process-card-visitor-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProcessTabsAbout = () => import('../../components/process/tabs/about/index.vue' /* webpackChunkName: "components/process-tabs-about" */).then(c => wrapFunctional(c.default || c))
export const ProcessTabsAboutMobile = () => import('../../components/process/tabs/about/mobile.vue' /* webpackChunkName: "components/process-tabs-about-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProcessTabsTask = () => import('../../components/process/tabs/task/index.vue' /* webpackChunkName: "components/process-tabs-task" */).then(c => wrapFunctional(c.default || c))
export const ProcessTabsTasksList = () => import('../../components/process/tabs/tasks-list/index.vue' /* webpackChunkName: "components/process-tabs-tasks-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
