import { Social } from '@/Services/ServiceOwners/Social';
import { AxiosResultType } from '@/Services/Types/AxiosResultType';

export class ChatService extends Social {
    private roomsRoot = `${this.serviceOwner}/chat`;

    public async getRooms(params): Promise<any> {
        let route = `${this.roomsRoot}/rooms`;
        try {
            const roomsResult: AxiosResultType = await this.get(route, params);
            return roomsResult as any;
        } catch (e) {
            return e.response;
        }
    }

    public async uploadVoice(formData: FormData): Promise<any> {
        let route = `/file/file-upload/infopack-asset`;
        try {
            const uploadResult: AxiosResultType = await this.post(route, formData);
            return uploadResult as any;
        } catch (e) {
            return e.response;
        }
    }

    public async getRoomMessages(id, { limit, offset }): Promise<any> {
        let route = `${this.roomsRoot}/rooms/${id}/messages?limit=${limit}&offset=${offset}`;
        try {
            const roomMessagesResult: AxiosResultType = await this.get(route);
            return roomMessagesResult as any;
        } catch (e) {
            return e.response;
        }
    }

    public async getUserNewMessages(): Promise<any> {
        let route = `${this.roomsRoot}/new-messages`;
        try {
            const userNewMessagesResult: AxiosResultType = await this.get(route);
            return userNewMessagesResult as any;
        } catch (e) {
            return e.response;
        }
    }

    /**
     * @description send direct message to a user with his id, without any socket connection
     * @param userId id of target user
     * @param message text
     * @returns Promise<any>
     */
    public async sendDirectMessage(userId: number, message: string): Promise<any> {
        const route = `${this.roomsRoot}/direct-message`;
        try {
            const directMessage: AxiosResultType = await this.post(route, { userId, message });
            return directMessage as any;
        } catch (e) {
            return e.response;
        }
    }
}
