import mut from './types';
const state = () => ({
    searchBar: false,
    mobileSearchPanel: false,
});

const getters = {
    searchBar: (state) => state.searchBar,
    mobileSearchPanel: (state) => state.mobileSearchPanel,
};

const mutations = {
    [mut.TOGGLE_SEARCHBAR](state) {
        state.searchBar = !state.searchBar;
    },
    [mut.SET_SEARCHBAR](state, payload) {
        state.searchBar = payload;
    },
    [mut.TOGGLE_MOBILE_SEARCH_PANEL](state) {
        state.mobileSearchPanel = !state.mobileSearchPanel;
    },
    [mut.SET_MOBILE_SEARCH_PANEL](state, payload) {
        state.mobileSearchPanel = payload;
    },
};

const actions = {
    toggleSearchBar({ commit }): void {
        commit(mut.TOGGLE_SEARCHBAR);
    },
    setSearchBar({ commit }, value: Boolean) {
        commit(mut.SET_SEARCHBAR, value);
    },
    toggleMobileSearchPanel({ commit }) {
        commit(mut.TOGGLE_MOBILE_SEARCH_PANEL);
    },
    setMobileSearchPanel({ commit }, value: Boolean) {
        commit(mut.SET_MOBILE_SEARCH_PANEL, value);
    },
};

export default {
    namespaced: true,

    state,
    mutations,
    actions,
    getters,
};
