import { render, staticRenderFns } from "./login.vue?vue&type=template&id=f1d52a0a&"
import script from "./login.vue?vue&type=script&lang=ts&"
export * from "./login.vue?vue&type=script&lang=ts&"
import style0 from "./login.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ISnackBar: require('/app/components/i/snack-bar/index.vue').default,ISnackBarLarge: require('/app/components/i/snack-bar/large.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VBtn,VIcon,VMain})
