export const ApiConfig = {
    uri: process.env.VUE_APP_API_URL,
    user: {
        uri: '/api/v1/ua',
        transactionWithdraw: 'transaction/withdraw',
        transactionUpload: 'transaction/status',
        accountingOtp: 'transaction/otp',
        accountingOtpVerify: 'transaction/otp/verify',
        updatePersonalInfo: 'user/personalInfo',
        updateBusinessInfo: 'user/businessInfo',
        updateBusinessIndustry: 'user/businessIndustry',
        updateBusinessContact: 'user/businessContact',
        updateContact: 'user/contact',
        updateBusinessBiography: 'user/businessBiography',
        updateEducation: 'user/educations',
        updateExperiences: 'user/experiences',
        updateDestinations: 'user/destinations',
        updateGeneralCertificates: 'user/generalCertificates',
        updateLanguageCertificates: 'user/languageCertificates',
    },

    infopack: {
        uri: '/api/v1/ip',
        pin: 'management/infopacks/pin',
    },

    gateway: {
        v1: '/gateway/v1/',
    },

    file: {
        uploadPublic: 'file/upload/public',
    },

    communication: {
        findNotifications: 'notification/find',
        findNotificationsCount: 'notification/count',
    },
};
