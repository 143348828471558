import { render, staticRenderFns } from "./noFooter.vue?vue&type=template&id=3efa55d2&"
import script from "./noFooter.vue?vue&type=script&lang=ts&"
export * from "./noFooter.vue?vue&type=script&lang=ts&"
import style0 from "./noFooter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/app/components/header/Header.vue').default,HeaderMobile: require('/app/components/header/Mobile.vue').default,ISnackBar: require('/app/components/i/snack-bar/index.vue').default,ISnackBarLarge: require('/app/components/i/snack-bar/large.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VApp,VAppBar,VMain,VOverlay})
