const servicePath = '/api/v1/ip';

export default {
    async getInfopackDetailTabData(token?: string, tabToken?: string): Promise<any> {
        try {
            const requestPrivateVersion = token.startsWith('private-');

            const response = await this.ctx.$axios.$get(
                `${servicePath}/infopacks/${encodeURIComponent(
                    requestPrivateVersion ? token.split('private-')[1] : token
                )}/tabs/${tabToken}`,
                {
                    params: { private: requestPrivateVersion ? 1 : null },
                }
            );

            return response.data;
        } catch (error) {
            throw error.response || error.notofication || error;
        }
    },

    async get(token: string): Promise<any> {
        try {
            let infoPackDetailDataResult;

            // logged in user
            if (this.ctx.$auth?.$state?.loggedIn) {
                const requestPrivateVersion = token.startsWith('private-');
                const route = `${servicePath}/infopacks/${encodeURIComponent(
                    requestPrivateVersion ? token.split('private-')[1] : token
                )}/auth/tabs`;
                infoPackDetailDataResult = await this.ctx.$axios.$get(route, {
                    params: {
                        private: requestPrivateVersion ? 1 : null,
                    },
                });
            }

            // guest user
            else {
                const route = `${servicePath}/infopacks/${token}/tabs`;
                infoPackDetailDataResult = await this.ctx.$axios.$get(route);
            }
            return infoPackDetailDataResult.data as any[];
        } catch (e) {
            throw e.response || e;
        }
    },

    async fetchMyInfopacks({
        offset = 0,
        limit = 10,
        status = [],
        pin = null,
        title = null,
    }: {
        offset: Number;
        limit: Number;
        status?: Array<number>;
        pin: boolean;
        title?: string;
    }): Promise<any> {
        try {
            const response = await this.ctx.$axios.$get(`${servicePath}/management/infopacks`, {
                params: { offset, limit, status, pin, title },
            });
            return response;
        } catch (error) {
            throw error.response || error.notofication || error;
        }
    },
    async fetchMyInfopacksOrProcess({
        offset = 0,
        limit = 10,
        status = [],
        pin = null,
        title = null,
        type = 'infopacks',
        exemptIds = [],
    }: {
        offset: Number;
        limit: Number;
        status?: Array<number>;
        pin: boolean;
        title?: string;
        type: string;
        exemptIds?: number[];
    }): Promise<any> {
        try {
            const response = await this.ctx.$axios.$get(`${servicePath}/${type}`, {
                params: { offset, limit, status, pin, title, exemptIds },
            });
            return response;
        } catch (error) {
            throw error.response || error.notofication || error;
        }
    },

    /**
     * Copies a chapter into a new Infopack
     *
     * @param  {number} input Chapter data
     *
     * @returns {Promise<any>}
     */

    async copyChapter({
        tabId,
        newInfopackIds,
        copySubChapters,
        type,
    }: {
        tabId: number;
        newInfopackIds: number;
        copySubChapters?: boolean;
        type: number;
    }): Promise<any> {
        try {
            const response = await this.ctx.$axios.$post(
                `${servicePath}/management/infopacks/copyChapter`,
                {
                    tabId,
                    newInfopackIds,
                    copySubChapters,
                    type,
                }
            );
            return response;
        } catch (error) {
            throw error.response || error.notofication || error;
        }
    },

    /**
     * @description fetch infopacks created by user
     * @param userId id of target user
     * @returns any
     */
    async getUserProfileInfopacks(userId: number): Promise<any> {
        try {
            const route = `${servicePath}/infopacks/${userId}`;
            const response = await this.ctx.$axios.get(route);
            return response?.data as any;
        } catch (e) {
            throw e.response;
        }
    },
};
