import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=60450371&"
import script from "./mobile.vue?vue&type=script&lang=ts&"
export * from "./mobile.vue?vue&type=script&lang=ts&"
import style0 from "./mobile.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountSidebar: require('/app/components/account/sidebar/index.vue').default,ISnackBar: require('/app/components/i/snack-bar/index.vue').default,ISnackBarLarge: require('/app/components/i/snack-bar/large.vue').default,SearchMobile: require('/app/components/search/mobile.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VApp,VMain,VNavigationDrawer})
