import { mapActions } from 'vuex';

export default {
    computed: {
        mxUserInfo() {
            return this.$auth.user;
        },
    },
    methods: {
        ...mapActions('snackbar', {
            setLargeSnackBar: 'setLargeSnackBar',
        }),
        mxCheckActivation(next: Function) {
            if (next && (this.mxUserInfo.emailVerified || this.mxUserInfo.phoneVerified)) {
                next();
            } else {
                this.setLargeSnackBar({
                    toggle: true,
                    color: 'red',
                    message: this.$t('info.messages.activateYourAccountToDoTheOperation'),
                    action: {
                        title: this.$t('info.actions.activate'),
                        titleClasses: 'red-4',
                        attrs: {
                            class: 'c-white',
                            link: true,
                            nuxt: true,
                            to: this.localePath(`/profile/${this.mxUserInfo.username}`),
                        },
                    },
                });
            }
        },
    },
};
