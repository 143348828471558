import Vuex from 'vuex';
import { infoPackDetailStoreModule } from '@/store/management/creation/infopackCreation.store';
import pack from '~/store/pack';
import profile from '~/store/profile';
import sidebar from '~/store/sibebar';
import search from '~/store/search';
import snackbar from '~/store/snackbar';
import chatStore from '~/store/module/chatStore';
import profileStore from '~/store/module/profileStore';
import searchListStore from '~/store/module/SearchListStore';

const createStore = (): any => {
    return new Vuex.Store({
        modules: {
            pack,
            profile,
            sidebar,
            search,
            snackbar,
            searchListStore: {
                // namespaced: true,
                ...searchListStore,
            },
            profileStore,
            chatStore: {
                namespaced: true,
                ...chatStore,
            },
            creationStore: {
                namespaced: true,
                ...infoPackDetailStoreModule,
            },
        },
        state: {
            app: {
                // device: {
                //   type: getDeviceType()
                // }
            },
            skeltonLoading: false,

            toggleDialog: false,
            toggleSearchInput: false,
            toggleDialogFormCampaign: false,
            errorSearchInput: false,
            // type: getDeviceType(),
            snackbarMessage: '',
            toggleSnackbar: false,
            snackbarColor: '',
            categs: [],
            authRegisterEmailOrphone: {},
            forgotPassword: {
                step: 1,
                verifyCodeData: {},
                emailOrPhone: '',
                oAuth: {},
            },
            register: {
                step: 1,
                verifyCodeData: {},
                emailOrPhone: '',
                oAuth: {},
            },
            isPhone: false,
            loginLoading: false,

            //infopackDetail
            infopackDetailData: {},
            infopackTabsContents: [],
            deploy: false,
            infopackDetailContentLoading: false,
            isInfoPackDetailPage: false,
            isHomePage: false,
            openChatModal: true,
            showWelcomeModal: false,
            isChatPage: false,

            // management store
            //TODO must change to module
            toggleWarning: false,
            deleteTab: false,
            uploadContent: {},
            uploadFlag: '',
            infopackAssetRef: '',
            uploadedFile: {},
            infopackDetail: {},

            serverDate: null,
        },
        getters: {
            getAuthRegisterEmailOrphone: (state) => state.authRegisterEmailOrphone,
            getForgotPassword: (state) => state.forgotPassword,
            getRegister: (state) => state.register,
            getIsPhone: (state) => state.isPhone,
            getLoginLoading: (state) => state.loginLoading,
            getIsHomePage: (state) => state.isHomePage,
            //infopackDetail
            getInfopackDetailData: (state) => state.infopackDetailData,
            getInfopackTabsContents: (state) => state.infopackTabsContents,
            getInfopackDetailContentLoading: (state) => state.infopackDetailContentLoading,
            getIsInfoPackDetailPage: (state) => state.isInfoPackDetailPage,
            getOpenChatModal: (state) => state.openChatModal,
            getIsChatPage: (state) => state.isChatPage,
            getShowWelcomeModal: (state) => state.showWelcomeModal,
            serverDate: (state) => state.serverDate,
        },
        actions: {
            setIsChatPage({ commit }, payload) {
                commit('updateIsChatPage', payload);
            },
            setIsHomePage({ commit }, payload) {},
            setAuthRegisterEmailOrphone({ commit }, payload) {
                commit('updateAuthRegisterEmailOrphone', payload);
            },
            setForgotPasswordVerifyCodeStep({ commit }, payload) {
                commit('updateForgotPasswordVerifyCodeStep', payload);
            },
            setForgotVerifyData({ commit }, payload) {
                commit('updateForgotVerifyData', payload);
            },
            setRegisterVerifyData({ commit }, payload) {
                commit('updateRegisterVerifyData', payload);
            },
            setForgotEmailOrPhone({ commit }, payload) {
                commit('updateForgotEmailOrPhone', payload);
            },
            setForgotoAuth({ commit }, payload) {
                commit('updateForgotoAuth', payload);
            },
            setRegisteroAuth({ commit }, payload) {
                commit('updateRegisteroAuth', payload);
            },

            setRegisterStep({ commit }, payload) {
                commit('updateRegisterStep', payload);
            },
            setRegisterEmailOrPhone({ commit }, payload) {
                commit('updateRegisterEmailOrPhone', payload);
            },
            setSnackBar({ commit }, payload) {
                commit('updateSnackBar', payload);
            },
            async loginUser({ dispatch, commit }, payload) {
                dispatch('setLoginLoading', true);

                await this.$auth
                    .loginWith('local', {
                        data: payload,
                    })
                    .then((response) => {
                        dispatch('setSnackBar', {
                            toggle: true,
                            color: 'green',
                            message: this.$i18n.messages[this.$i18n.locale].signIn.successLogin,
                        });
                        if (this.$router.currentRoute.query.callbackRoute) {
                            const { callbackRoute } = this.$router.currentRoute.query;
                            commit('updateShowWelcomeModal', true);
                            this.$router.push(this.localePath(callbackRoute));
                        } else {
                            this.$router.push(this.localePath({ path: '/' }));
                        }
                    })
                    .catch((e) => {
                        if (e.response.status >= 500) {
                            dispatch('setSnackBar', {
                                toggle: true,
                                message: e.response.statusText,
                                color: 'red',
                            });
                        } else {
                            dispatch('setSnackBar', {
                                toggle: true,
                                message:
                                    this.$i18n.messages[this.$i18n.locale].auth.messages
                                        .invalid_username_password,
                                color: 'red',
                            });
                        }
                    });

                dispatch('setLoginLoading', false);
            },

            setIsPhone({ commit }, payload) {
                commit('updateIsPhone', payload);
            },

            setLoginLoading({ commit }, payload) {
                commit('updateLoginLoading', payload);
            },

            //infopackDetail
            setInfopackDetailData({ commit, dispatch }, payload) {
                commit('updateInfopackDetailData', payload.data);
            },
            setInfopackTabsContents({ commit }, payload) {
                commit('updateInfopackTabsContents', payload);
            },
            setInfopackDetailContentLoading({ commit }, payload) {
                commit('updateInfopackDetailContentLoading', payload);
            },
            setIsInfoPackDetailPage({ commit }, payload) {
                commit('updateIsInfoPackDetailPage', payload);
            },

            setOpenChatModal({ commit }, payload) {
                commit('updateOpenChatModal', payload);
            },
            setCategs({ commit }, payload) {
                commit('setCategs', payload);
            },
            setServerDate({ commit }, payload) {
                commit('SET_SERVER_DATE', payload);
            },
        },
        mutations: {
            setCategs(state, cats) {
                state.categs = cats;
            },
            changeSkletonLoading(state, { skeltonLoading }) {
                state.skeltonLoading = skeltonLoading;
            },
            setUploadContent(state, payload) {
                state.uploadContent = payload;
            },
            updateShowWelcomeModal(state, payload) {
                state.showWelcomeModal = payload;
            },
            updateIsChatPage(state, payload) {
                state.isChatPage = payload;
            },
            updateOpenChatModal(state, payload) {
                state.openChatModal = payload;
            },
            updateIsHomePage(state, payload) {
                state.isHomePage = payload;
            },
            updateIsInfoPackDetailPage(state, payload) {
                state.isInfoPackDetailPage = payload;
            },
            updateIsPhone(state, payload) {
                state.isPhone = payload;
            },
            updateLoginLoading(state, payload) {
                state.loginLoading = payload;
            },
            updateSnackBar(state, payload) {
                state.toggleSnackbar = payload.toggle;
                state.snackbarColor = payload.color;
                state.snackbarMessage = payload.message;
            },
            updateAuthRegisterEmailOrphone(state, payload) {
                state.authRegisterEmailOrphone = payload;
            },
            updateForgotPasswordVerifyCodeStep(state, payload) {
                state.forgotPassword = { ...state.forgotPassword, step: payload };
            },
            updateForgotVerifyData(state, payload) {
                state.forgotPassword.verifyCodeData = { ...payload };
            },
            updateRegisterVerifyData(state, payload) {
                state.register.verifyCodeData = { ...payload };
            },
            updateForgotEmailOrPhone(state, payload) {
                state.forgotPassword.emailOrPhone = payload;
            },
            updateForgotoAuth(state, payload) {
                state.forgotPassword.oAuth = { ...payload };
            },

            updateRegisteroAuth(state, payload) {
                state.register.oAuth = { ...payload };
            },

            updateRegisterStep(state, payload) {
                state.register = { ...state.register, step: payload };
            },
            updateRegisterEmailOrPhone(state, payload) {
                state.register.emailOrPhone = payload;
            },

            //infopackDetail
            updateInfopackDetailData(state, payload) {
                state.infopackDetailData = payload;
            },

            updateInfopackTabsContents(state, paylaod) {
                let isUinq = true;

                state.infopackTabsContents.map((infopackTabsContent) => {
                    if (
                        infopackTabsContent.infopackRef === paylaod.infoPackId &&
                        infopackTabsContent.id === paylaod.infopackContent.id
                    ) {
                        isUinq = false;
                    }
                });
                if (isUinq) {
                    state.infopackTabsContents.push(paylaod.infopackContent);
                }
            },

            updateInfopackDetailContentLoading(state, payload) {
                state.infopackDetailContentLoading = payload;
            },
            SET_SERVER_DATE(state, payload) {
                state.serverDate = payload;
            },
        },
    });
};
export default createStore as any;
