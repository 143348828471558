const state = {
    links: [
        { id: 0, title: 'title1', desc: 'this is a test desc', url: 'https://www.google.com' },
        { id: 1, title: 'title2', desc: 'this is a test desc', url: 'https://www.digikala.com' },
        { id: 2, title: 'title3', desc: 'this is a test desc', url: 'https://www.amazon.com' },
        { id: 3, title: 'title4', desc: 'this is a test desc', url: 'https://www.divar.ir' },
    ],
    images: [
        {
            id: 1,
            alt: 'alt1',
            name: 'name1',
            desc: 'sdfsf sdfsd fss fsdf this is a test description for testing',
            image: 'https://www.alef.ir/files/post/lg/2019/15/111372.jpg',
        },
        {
            id: 2,
            alt: 'alt2',
            name: 'name2',
            desc: 'this is a test description for testing',
            image: 'https://www.alef.ir/files/post/lg/2019/15/111372.jpg',
        },
        {
            id: 3,
            alt: 'alt3',
            name: 'name3',
            desc: 'this is a test description for testing',
            image: 'https://www.alef.ir/files/post/lg/2019/15/111372.jpg',
        },
        {
            id: 4,
            alt: 'alt4',
            name: 'name4',
            desc: 'this is a test description for testing',
            image: 'https://www.alef.ir/files/post/lg/2019/15/111372.jpg',
        },
        {
            id: 5,
            alt: 'alt5',
            name: 'name5',
            desc: 'this is a test description for testing',
            image: 'https://www.alef.ir/files/post/lg/2019/15/111372.jpg',
        },
        {
            id: 6,
            alt: 'alt6',
            name: 'name6',
            desc: 'this is a test description for testing',
            image: 'https://www.alef.ir/files/post/lg/2019/15/111372.jpg',
        },
    ],
    files: [
        { id: 1, name: 'pdffile.pdf', title: 'this is a test title for files' },
        { id: 2, name: 'pdffile.pdf', title: 'this is a test title for files' },
        { id: 3, name: 'test.txt', title: 'this is a test title for files' },
        { id: 4, name: 'docfile.doc', title: 'this is a test title for files' },
    ],
    videos: [
        {
            id: 1,
            name: 'video',
            title: 'this is a test title for files',
            url: 'https://www.aparat.com/v/1j8TJ',
        },
        {
            id: 2,
            name: 'video',
            title: 'this is a test title for files',
            url: 'https://youtu.be/C4UUmQVZZEQ',
        },
        {
            id: 3,
            name: 'video',
            title: 'this is a test title for files',
            url: 'https://www.aparat.com/v/1j8TJ',
        },
        {
            id: 4,
            name: 'video',
            title: 'this is a test title for files',
            url: 'https://youtu.be/C4UUmQVZZEQ',
        },
        {
            id: 5,
            name: 'video',
            title: 'this is a test title for files',
            url: 'https://www.aparat.com/v/1j8TJ',
        },
    ],
    testValue: ``,
    content: ``,
};

const mutations = {
    getLinks(state: any, params: any) {
        state.links.push(params);
    },
    getContent(state: any, params: any) {
        state.content.push(params);
    },
};

const actions = {
    getLinks(context: any, payload: any) {
        context.commit('getLinks', payload);
    },
    getContent(context: any, payload: any) {
        context.commit('getContent', payload);
    },
};

export default {
    state,
    mutations,
    actions,
} as any;
