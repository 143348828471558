const servicePath = '/api/v1/ip';
export default {
    /**
     * @description send a request to the admin for a specific subscription
     * @param packId id of infopack or process
     * @param id id of subscription
     * @returns object
     */
    async subscribe(packId: string, id: string): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${packId}/subscribe/${id}`;

            const response = await this.ctx.$axios.$post(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description send a request for specific subscription of a pack
     * @param packId id of infopack or process
     * @param id id of subscription
     * @returns object
     */
    async request(packId: string, id: string): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${packId}/subscribe/${id}/request`;

            const response = await this.ctx.$axios.$post(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description fetch list of all requests for the pack
     * @param packId id of infopack or process
     * @returns object
     */
    async getPackRequestsList(packId: string): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${packId}/subscribe/request`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description fetch list of requests for the pack with specific subscription
     * @param packId id of infopack or process
     * @param id id of subscription
     * @returns object
     */
    async getPackRequestsListOfSubscription(packId: string, id: string): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${packId}/subscribe/${id}/request`;

            const response = await this.ctx.$axios.$get(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description approve the request for specific subscription of a pack
     * @param packId id of infopack or process
     * @param subscriptionRequestId id of subscription request id
     * @returns object
     */
    async approve(packId: string, subscriptionRequestId: string): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${packId}/approve/${subscriptionRequestId}`;

            const response = await this.ctx.$axios.$post(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description reject the request for specific subscription of a pack
     * @param packId id of infopack or process
     * @param subscriptionRequestId id of subscription Request id
     * @returns object
     */
    async reject(packId: string, subscriptionRequestId: string): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${packId}/reject/${subscriptionRequestId}`;

            const response = await this.ctx.$axios.$delete(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description restore the request for specific subscription of a pack
     * @param packId id of infopack or process
     * @param subscriptionRequestId id of subscription Request id
     * @returns object
     */
    async restore(packId: string, subscriptionRequestId: string): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${packId}/restore/${subscriptionRequestId}`;

            const response = await this.ctx.$axios.$post(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description cancel the request for specific subscription of a pack
     * @param packId id of infopack or process
     * @param subscriptionId id of subscription id
     * @returns object
     */
    async cancel(packId: string, subscriptionId: string): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${packId}/subscribe/${subscriptionId}/request`;

            const response = await this.ctx.$axios.$delete(route);
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },

    /**
     * @description fetch list of requests for the pack with specific subscription
     * @param packId id of infopack or process
     * @param status array of statuses
     * @returns object
     */
    async members(packId: string, status: number[]): Promise<any> {
        try {
            const route = `${servicePath}/subscription/${packId}/members`;

            const response = await this.ctx.$axios.$get(route, { params: { status } });
            return response?.data as any;
        } catch (error) {
            throw error.response || error;
        }
    },
};
