interface URIContract {
    base: string;
    endpoint: string;
    query?: any;
}

/**
 * Creates url with query params
 *
 * @param  {URIContract} input url object
 *
 * @returns {string} compiled query
 */
export const URI = (input: URIContract): string => {
    let url = `${input.base}/${input.endpoint}`;
    if (input.query) {
        url += `?${new URLSearchParams(input.query).toString()}`;
    }

    return url;
};
