




















export default {
    props: {
        width: {
            type: String,
            default: '368px',
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        mobileFullscreen: {
            type: Boolean,
            default: false,
        },
        height: undefined,
    },
    data() {
        return { modal: false, comp: undefined, inputData: undefined, eventListener: undefined };
    },
    watch: {
        modal(val) {
            if (!val) {
                this.inputData = undefined;
                this.comp = undefined;
            }
        },
    },
    methods: {
        closeModal() {
            this.modal = false;
        },
        openModal<Type>(val, data: Type, eventListener) {
            this.comp = val;
            this.inputData = data;
            this.eventListener = eventListener;
            this.modal = true;
        },
        updateProps(props) {
            this.inputData = { ...this.inputData, ...props };
        },
    },
};
