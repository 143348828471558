//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            showWalletModal: false,
        };
    },
    computed: {
        isBussiness() {
            return this.userInfo?.role === 'business';
        },
        userInfo() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        toggleWalletModal() {
            this.showWalletModal = !this.showWalletModal;
        },
        logout() {
            this.$logout();
        },
        openProfile() {
            this.$router.push(this.localePath('/profile'));
        },
    },
};
