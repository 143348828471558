const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['meta'] = require('../middleware/meta.ts')
middleware['meta'] = middleware['meta'].default || middleware['meta']

middleware['mobile'] = require('../middleware/mobile.js')
middleware['mobile'] = middleware['mobile'].default || middleware['mobile']

middleware['skeleton'] = require('../middleware/skeleton.js')
middleware['skeleton'] = middleware['skeleton'].default || middleware['skeleton']

export default middleware
