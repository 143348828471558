import { SocketSdk } from '@/Services/Socket/socket-sdk';

export default (context, inject) => {
    const redirectTo = function (t) {
        if (process.server) {
            context.redirect(t);
        } else {
            context.app.router.replace(t);
        }
    };
    inject('redirectTo', redirectTo);

    const share = function (title) {
        if (navigator.share) {
            navigator.share({
                title,
                text: `Check out ${title}`,
                url: this.$route.fullPath,
            });
        } else {
            context.store.dispatch('setSnackBar', {
                toggle: true,
                message: 'مرورگر شما از این ویژگی پشتیبانی نمی‌کند',
                color: 'red',
            });
        }
    };
    inject('share', share);

    const apiErrorHandler = function (error) {
        const serverRedirectKeys = ['NOT_FOUND_INFOPACK'];
        context.store.dispatch('setSnackBar', {
            toggle: true,
            message:
                error?.response?.data?.notification?.message ||
                error.message ||
                'خطا در دریافت اطلاعات',
            color: 'red',
        });
        if (
            error.response.status === 404 ||
            serverRedirectKeys.includes(error?.response?.data?.notification?.key)
        ) {
            context.$redirectTo(`/fa/notFound`);
        } else if (error.response.status >= 500) {
            context.$redirectTo(`/`);
        }
    };
    inject('apiErrorHandler', apiErrorHandler);

    const logout = async function () {
        SocketSdk.deleteInstance();
        await this.$auth.logout();
        this.$router.push(this.localePath('/'));
        setTimeout(() => {
            context.store.dispatch('setSnackBar', {
                toggle: true,
                message: 'خدانگهدار',
                color: 'green',
            });
        }, 1500);
    };
    inject('logout', logout);

    const disableScrolling = function () {
        if (!window) return;
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
        window.onwheel = function () {
            window.scrollTo(x, y);
        };
    };
    inject('disableScrolling', disableScrolling);

    const enableScrolling = function () {
        if (!window) return;
        window.onscroll = function () {};
        window.onwheel = function () {};
    };
    inject('enableScrolling', enableScrolling);

    const scrollToTop = function () {
        if (!window) {
            return;
        }

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };
    inject('scrollToTop', scrollToTop);
};
