import { SHOW_SIDEBAR, HIDE_SIDEBAR, TOGGLE_SIDEBAR } from './types';

const state = () => ({
    showSidebar: false,
});

const getters = {
    showSidebar: (state) => state.showSidebar,
};

const mutations = {
    [SHOW_SIDEBAR](state) {
        state.showSidebar = true;
    },
    [HIDE_SIDEBAR](state) {
        state.showSidebar = false;
    },
    [TOGGLE_SIDEBAR](state) {
        state.showSidebar = !state.showSidebar; // TODO test me
    },
};

const actions = {
    showSidebar({ commit }) {
        commit(SHOW_SIDEBAR);
    },
    hideSidebar({ commit }) {
        commit(HIDE_SIDEBAR);
    },
    toggleSidebar({ commit }) {
        commit(TOGGLE_SIDEBAR);
    },
};

export default {
    namespaced: true,

    state,
    mutations,
    actions,
    getters,
};
