import { ApiConfig } from '~/config/api.config';

export default {
    /**
     * Returns user notifications
     *
     * @returns {Promise<Notification | never>} User notifications || throws an error
     */
    async findByUser(limit: number = 20, offset: number = 0): Promise<Notification | never> {
        try {
            const basePath = `${ApiConfig.gateway.v1}${ApiConfig.communication.findNotifications}`;

            const route = `${basePath}?limit=${limit}&offset=${offset}`;
            return (await this.ctx.$axios.$get(route))?.data;
        } catch (error: any) {
            throw error.response || error;
        }
    },

    /**
     * Returns user notifications
     *
     * @returns {Promise<Notification | never>} User notifications || throws an error
     */
    async findCount(): Promise<number | never> {
        try {
            const limit = 20;
            const offset = 0;
            const basePath = `${ApiConfig.gateway.v1}${ApiConfig.communication.findNotificationsCount}`;

            const route = `${basePath}?limit=${limit}&offset=${offset}`;
            return (await this.ctx.$axios.$get(route))?.data?.total;
        } catch (error: any) {
            throw error.response || error;
        }
    },
};
