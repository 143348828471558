



























import { Vue, Component } from 'nuxt-property-decorator';

@Component({
    components: {},
})
export default class Default extends Vue {
    header: string = '';

    created() {
        this.viewMode();
    }

    viewMode(): any {
        if (process.client) {
            if (window.innerWidth < 960) {
                this.header = 'mobile';
                // this.$route.meta.navbar.mobile.viewMode = 'mobile'
            } else {
                this.header = 'desktop';
                // this.$route.meta.navbar.mobile.viewMode = 'desktop'
            }
            return this.header;
        }
    }
}
