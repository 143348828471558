//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import checkActivation from '~/mixinsjs/active-account';

export default {
    mixins: [checkActivation],
    computed: {
        userInfo() {
            return this.$store.state.auth.user;
        },
        activeAccount() {
            return this.userInfo.emailVerified || this.userInfo.phoneVerified;
        },
    },
    methods: {
        activateAccountAlert() {
            if (!this.activeAccount) {
                this.mxCheckActivation();
            }
        },
    },
};
