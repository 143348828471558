import { Plugin } from '@nuxt/types';
import { initializeAxios } from '~/Services/Api/axios';
import { appConfig } from '~/Services/helper';

const accessor: Plugin = ({ $axios, route, redirect, i18n, store }) => {
    initializeAxios($axios);

    $axios.onRequest((req) => {
        // i18n.localeProperties.code

        $axios.setHeader(
            'Accept-Language',
            i18n.localeProperties.code ?? appConfig.defaultLanguage
        );
        if (req.headers?.['x-authorization']) {
            $axios.setHeader('x-authorization', req.headers?.['x-authorization']);
        }
    });

    $axios.onResponse(({ data, config }) => {
        /*
    NOTE
    We have a lot of management apis that update the whole content (infopack or process)
    They don't return any value for us to update the whole object. So we have to update the whole object manually.

    We use `updatedAt` key to show "Publish" or "Publish Changes" buttons in toolbar and somewhere else. So we have to do it.

    Some apis like 'copy' have exact format like the others, but they should not update the key! So we exclude them.
    */
        if (
            config.url.startsWith('/api/v1/ip/management/infopacks') &&
            !config.url.endsWith('/copy') &&
            config.method !== 'get'
        ) {
            const now = new Date();
            const yesterdayDate = now.setDate(now.getDate() - 1);

            // Here we set published at to an older date to stimulate publish changes environment
            const updatedKeys = {
                updatedAt: data.meta.date,
                publishedAt: yesterdayDate,
            };

            // on publish or publish changes, we have to update its key manually!
            if (/\/management\/infopacks\/(\d)+\/publish(\/changes)?$/.test(config.url)) {
                updatedKeys.updatedAt = data.data.infopack?.updatedAt ?? data.data.updateadAt;
                updatedKeys.publishedAt = data.data.infopack?.publishedAt ?? data.data.publishedAt;
            }
            store.dispatch('creationStore/setInfoPackCreationData', updatedKeys);
        }
        if (data.meta?.date) {
            store.dispatch('setServerDate', new Date(data.meta.date));
        }
    });
};

export default accessor;
