// import { loadLanguageAsync } from '@/i18n';
import { Vue } from 'nuxt-property-decorator';
import { appConfig, languages, LanguageType } from '@/Services/helper';
import { loadLanguageAsync } from '~/helpers/i18n/i18n';

export class LanguageService {
    static setLanguage(vue: Vue, language: LanguageType) {
        vue.$i18n.locale = language.lang;
        vue.$vuetify.rtl = language.isRtl;
        loadLanguageAsync(language.lang);
    }

    getLanguages() {
        return languages;
    }

    static getCurrentLanguage(vue: Vue): string {
        return vue?.$i18n.localeProperties.code ?? appConfig.defaultLanguage;
    }
}
