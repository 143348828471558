import Vue from 'vue';
import { extend, localize, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import variables from '~/assets/json-collections/variables';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

const dictionary = {
    en: {
        messages: {
            required: (_, values) => `${values._field_} Required`,
            required_if: (_, values) =>
                `${values._field_} Required if ${values.target} is ${values.values}`,
            max: (_, values) =>
                `${values._field_} must not be more than ${values.length} characters`,
            max_value: (_, values) => `${values._field_} must not be greater than ${values.max}`,
            min_value: (_, values) => `${values._field_} must not be less than ${values.min}`,
            numeric: (_, values) => `${values._field_} must be numeric`,
            number: (_, values) => `${values._field_} must be number`,
            email: (_, values) => `${values._field_} must be a valid email address`,
            mobile: (_, values) => `${values._field_} must be a valid mobile number`,
            organizationPhone: (_, values) => `${values._field_} must be a valid phone number`,
            mobileWithCountryCode: (_, values) =>
                `${values._field_} must be a valid mobile number with country code`,
            url: (_, values) => `${values._field_} must be a valid url address`,
            linkedin: (_, values) => `${values._field_} must be a linkedin profile link`,
            arrMaxLength: (_, values) =>
                `${values._field_} must not be more than ${values.target} items`,
        },
    },
    fa: {
        messages: {
            required: (_, values) => `${values._field_} الزامی است`,
            required_if: (_, values) =>
                `${values._field_} اجباری است اگر ${values.target} مقدار ${
                    values.values ? `${values.values} نداشته باشد` : `داشته باشد`
                }`,
            max: (_, values) =>
                `${values._field_} نباید بیشتر از ${values.length} کاراکتر داشته باشد`,
            max_value: (_, values) => `${values._field_} نباید بیشتر از ${values.max} باشد`,
            min_value: (_, values) => `${values._field_} نباید کمتر از ${values.min} باشد`,
            numeric: (_, values) => `${values._field_} باید فقط شامل حروف عددی باشد`,
            number: (_, values) => `${values._field_} باید عدد باشد`,
            email: (_, values) => `${values._field_} باید یک آدرس معتبر باشد`,
            mobile: (_, values) => `${values._field_} باید یک شماره معتبر باشد`,
            organizationPhone: (_, values) => `${values._field_} باید یک شماره تلفن معتبر باشد`,
            mobileWithCountryCode: (_, values) =>
                `${values._field_} باید یک شماره معتبر به همراه کد کشور باشد`,
            url: (_, values) => `${values._field_} باید یک لینک معتبر باشد`,
            linkedin: (_, values) => `${values._field_} باید آدرس پروفایل لینکدین باشد`,
            arrMaxLength: (_, values) =>
                `${values._field_} نباید بیشتر از ${values.target} مورد داشته باشد`,
        },
    },
};

// Add rules
Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});

// TODO support both languages
extend('biggerThan', {
    params: ['target'],
    validate(value, { target }) {
        if (!target) return true;
        return value >= target;
    },
    message: 'این فیلد باید بزرگ‌تر از {target} باشد',
});

extend('smallerThan', {
    params: ['target'],
    validate(value, { target }) {
        if (!target) return true;
        return value <= target;
    },
    message: 'این فیلد باید کوچک‌تر از {target} باشد',
});

extend('url', {
    validate: (value) => variables.urlRegex.test(value),
});

extend('linkedin', {
    validate: (value) => variables.linkedinLinkRegex.test(value),
});

extend('mobile', {
    validate: (value) => variables.mobileRegex.test(value),
});

extend('organizationPhone', {
    validate: (value) => variables.organizationPhone.test(value),
});

extend('mobileWithCountryCode', {
    validate: (value) => variables.mobileWithCountryCodeRegex.test(value),
});

extend('arrMaxLength', {
    params: ['target'],
    validate: (value, { target }) => value.length <= target,
});

extend('number', {
    validate: (value) => variables.number.test(value),
});

export default ({ app }) => {
    const lang = app.i18n.localeProperties.code === 'fa' ? 'fa' : 'en';

    // localizing
    localize(dictionary);
    localize(lang);
};
